define("api/models/host", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/host", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _host, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let HostModel = (_dec = (0, _model.attr)('string', {
    description: 'The address (DNS or IP name) used to reach the host',
    isNestedAttribute: true
  }), _dec2 = (0, _model.attr)({
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec3 = (0, _model.attr)('string', {
    description: '',
    readOnly: true
  }), _dec4 = (0, _model.attr)({
    readOnly: true,
    emptyArrayIfMissing: true
  }), (_class = class HostModel extends _host.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "address", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ip_addresses", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "external_id", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dns_names", _descriptor4, this);
    }

    /**
     * True if the host is static.
     * @type {boolean}
     */
    get isStatic() {
      return this.type === 'static';
    }
    /**
     * True if the host is plugin.
     * @type {boolean}
     */


    get isPlugin() {
      return this.type === 'plugin';
    }
    /**
     * True if the host plugin type is AWS.
     * @type {boolean}
     */


    get isAWS() {
      return this.compositeType === 'aws';
    }
    /**
     * True if the host plugin type is Azure.
     * @type {boolean}
     */


    get isAzure() {
      return this.compositeType === 'azure';
    }
    /**
     * If host is a plugin return `plugin.name`,
     * otherwise return the host type.
     * @type {string}
     */


    get compositeType() {
      return this.isPlugin ? this.plugin.name : this.type;
    }
    /**
     * Sets `type`. If type is different than `static`, sets `type` to `plugin`
     * and `plugin.name` to the specified type.
     */


    set compositeType(type) {
      if (type === 'static') {
        this.type = 'static';
      } else {
        this.type = 'plugin';
        this.plugin = {
          name: type
        };
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "address", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ip_addresses", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "external_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dns_names", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HostModel;
});