define("ember-data/index", ["exports", "ember-inflector", "@ember/error", "@ember/version", "@ember-data/adapter", "@ember-data/adapter/error", "@ember-data/adapter/json-api", "@ember-data/adapter/rest", "@ember-data/debug", "@ember-data/model", "@ember-data/serializer", "@ember-data/serializer/-private", "@ember-data/serializer/json", "@ember-data/serializer/json-api", "@ember-data/serializer/rest", "@ember-data/serializer/transform", "@ember-data/store", "ember-data/-private", "ember-data/setup-container"], function (_exports, _emberInflector, _error, _version, _adapter, _error2, _jsonApi, _rest, _debug, _model, _serializer, _private, _json, _jsonApi2, _rest2, _transform, _store, _private2, _setupContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_version.VERSION.match(/^1\.([0-9]|1[0-2])\./)) {
    throw new _error.default('Ember Data requires at least Ember 1.13.0, but you have ' + _version.VERSION + '. Please upgrade your version of Ember, then upgrade Ember Data.');
  }

  _private2.DS.Store = _store.default;
  _private2.DS.PromiseArray = _private2.PromiseArray;
  _private2.DS.PromiseObject = _private2.PromiseObject;
  _private2.DS.PromiseManyArray = _private2.PromiseManyArray;
  _private2.DS.Model = _model.default;
  _private2.DS.RootState = _private2.RootState;
  _private2.DS.attr = _model.attr;
  _private2.DS.Errors = _private2.Errors;
  _private2.DS.InternalModel = _private2.InternalModel;
  _private2.DS.Snapshot = _private2.Snapshot;
  _private2.DS.Adapter = _adapter.default;
  _private2.DS.AdapterError = _error2.default;
  _private2.DS.InvalidError = _error2.InvalidError;
  _private2.DS.TimeoutError = _error2.TimeoutError;
  _private2.DS.AbortError = _error2.AbortError;
  _private2.DS.UnauthorizedError = _error2.UnauthorizedError;
  _private2.DS.ForbiddenError = _error2.ForbiddenError;
  _private2.DS.NotFoundError = _error2.NotFoundError;
  _private2.DS.ConflictError = _error2.ConflictError;
  _private2.DS.ServerError = _error2.ServerError;
  _private2.DS.errorsHashToArray = _error2.errorsHashToArray;
  _private2.DS.errorsArrayToHash = _error2.errorsArrayToHash;
  _private2.DS.Serializer = _serializer.default;
  _private2.DS.DebugAdapter = _debug.default;
  _private2.DS.RecordArray = _private2.RecordArray;
  _private2.DS.AdapterPopulatedRecordArray = _private2.AdapterPopulatedRecordArray;
  _private2.DS.ManyArray = _private2.ManyArray;
  _private2.DS.RecordArrayManager = _private2.RecordArrayManager;
  _private2.DS.RESTAdapter = _rest.default;
  _private2.DS.BuildURLMixin = _adapter.BuildURLMixin;
  _private2.DS.RESTSerializer = _rest2.default;
  _private2.DS.JSONSerializer = _json.default;
  _private2.DS.JSONAPIAdapter = _jsonApi.default;
  _private2.DS.JSONAPISerializer = _jsonApi2.default;
  _private2.DS.Transform = _transform.default;
  _private2.DS.DateTransform = _private.DateTransform;
  _private2.DS.StringTransform = _private.StringTransform;
  _private2.DS.NumberTransform = _private.NumberTransform;
  _private2.DS.BooleanTransform = _private.BooleanTransform;
  _private2.DS.EmbeddedRecordsMixin = _rest2.EmbeddedRecordsMixin;
  _private2.DS.belongsTo = _model.belongsTo;
  _private2.DS.hasMany = _model.hasMany;
  _private2.DS.Relationship = _private2.Relationship;
  _private2.DS._setupContainer = _setupContainer.default;
  Object.defineProperty(_private2.DS, 'normalizeModelName', {
    enumerable: true,
    writable: false,
    configurable: false,
    value: _store.normalizeModelName
  });
  var _default = _private2.DS;
  _exports.default = _default;
});