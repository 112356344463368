define("core/components/loading-button/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _tracking, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Rose::Button
    ...attributes
    @style='inline-link-action'
    @iconLeft={{if
      this.isLoading
      'flight-icons/svg/loading-16'
      'flight-icons/svg/reload-16'
    }}
    class={{if
      this.isLoading
      'loading-button is-loading'
      'loading-button'
    }}
    @disabled={{this.isLoading}}
    {{on 'click' this.toggleRefresh}}
  >
    {{yield}}
  </Rose::Button>
  
  */
  {
    "id": "PabuxTU8",
    "block": "[[[8,[39,0],[[17,1],[16,0,[52,[30,0,[\"isLoading\"]],\"loading-button is-loading\",\"loading-button\"]],[4,[38,2],[\"click\",[30,0,[\"toggleRefresh\"]]],null]],[[\"@style\",\"@iconLeft\",\"@disabled\"],[\"inline-link-action\",[52,[30,0,[\"isLoading\"]],\"flight-icons/svg/loading-16\",\"flight-icons/svg/reload-16\"],[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"rose/button\",\"if\",\"on\",\"yield\"]]",
    "moduleName": "core/components/loading-button/index.hbs",
    "isStrictMode": false
  });

  let LoadingButton = (_class = class LoadingButton extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor, this);
    }

    async toggleRefresh() {
      this.isLoading = true;

      try {
        await this.args.onClick();
      } catch (e) {
        console.error('Error while loading data', e);
      }

      (0, _runloop.later)(() => this.isLoading = false, 1000);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRefresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRefresh"), _class.prototype)), _class);
  _exports.default = LoadingButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LoadingButton);
});