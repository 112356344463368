define("ember-inflector/lib/ext/string", ["ember", "ember-inflector/lib/system/string", "@ember/application/deprecations"], function (_ember, _string, _deprecations) {
  "use strict";

  if (_ember.default.ENV.EXTEND_PROTOTYPES === true || _ember.default.ENV.EXTEND_PROTOTYPES.String) {
    /**
      See {{#crossLink "Ember.String/pluralize"}}{{/crossLink}}
       @method pluralize
      @for String
    */
    Object.defineProperty(String.prototype, 'pluralize', {
      get() {
        (false && !(false) && (0, _deprecations.deprecate)(`String.prototype.pluralize() is deprecated. Please explicitly: import { pluralize } from 'ember-inflector';`, false, {
          id: 'ember-inflector.globals',
          until: '3.0.0'
        }));
        return function () {
          return (0, _string.pluralize)(this);
        };
      }

    }, {
      configurable: true
    });
    /**
      See {{#crossLink "Ember.String/singularize"}}{{/crossLink}}
       @method singularize
      @for String
    */

    Object.defineProperty(String.prototype, 'singularize', {
      get() {
        (false && !(false) && (0, _deprecations.deprecate)(`String.prototype.singularize() is deprecated. Please explicitly: import { singularize } from 'ember-inflector';`, false, {
          id: 'ember-inflector.globals',
          until: '3.0.0'
        }));
        return function () {
          return (0, _string.singularize)(this);
        };
      }

    }, {
      configurable: true
    });
  }
});