define("ember-truth-helpers/helpers/not-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notEqualHelper = notEqualHelper;
  _exports.default = void 0;

  function notEqualHelper(params) {
    return params[0] !== params[1];
  }

  var _default = (0, _helper.helper)(notEqualHelper);

  _exports.default = _default;
});