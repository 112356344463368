define("ember-notify/templates/components/ember-notify", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uX5gDYmJ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"messages\"]]],null]],null],null,[[[41,[48,[30,4]],[[[1,\"    \"],[8,[39,4],[[24,0,\"ember-notify clearfix\"]],[[\"@message\",\"@theme\",\"@closeAfter\"],[[30,1],[30,0,[\"theme\"]],[30,0,[\"closeAfter\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,4,[[30,2],[30,3]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[24,0,\"ember-notify clearfix\"]],[[\"@message\",\"@theme\",\"@closeAfter\"],[[30,1],[30,0,[\"theme\"]],[30,0,[\"closeAfter\"]]]],null],[1,\"\\n\"]],[]]]],[1]],null]],[\"message\",\"message\",\"close\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"has-block\",\"ember-notify/message\",\"yield\"]]",
    "moduleName": "ember-notify/templates/components/ember-notify.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});