define("api/serializers/credential-library", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialLibrarySerializer extends _application.default {
    /**
     * @override
     * @method serialize
     * @param {Snapshot} snapshot
     */
    serialize() {
      const serialized = super.serialize(...arguments);

      if (serialized.attributes) {
        // Serialize `http_request_body` only if `http_method` is POST
        if (!serialized.attributes?.http_method?.match(/post/i)) delete serialized.attributes.http_request_body;
      }

      return serialized;
    }

  }

  _exports.default = CredentialLibrarySerializer;
});