define("api/abilities/group", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for groups.
   */
  class GroupAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canAddMembers() {
      return this.hasAuthorizedAction('add-members');
    }
    /**
     * @type {boolean}
     */


    get canRemoveMembers() {
      return this.hasAuthorizedAction('remove-members');
    }

  }

  _exports.default = GroupAbility;
});