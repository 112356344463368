define("rose/components/rose/button/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    ...attributes
    type={{if @submit 'submit' 'button'}}
    disabled={{if @disabled 'disabled'}}
    class='{{@classNames}}
      {{if @style (concat 'rose-button-' @style)}}
      {{if @iconLeft 'has-icon-left'}}
      {{if @iconRight 'has-icon-right'}}
      {{if @iconOnly 'has-icon-only'}}'
  >
  
    <span class='rose-button-wrapper'>
      {{#if @iconLeft}}
        <Rose::Icon @name={{@iconLeft}} @size='medium' />
      {{/if}}
  
      <span class='rose-button-body'>
        {{yield}}
      </span>
  
      {{#if @iconOnly}}
        <Rose::Icon @name={{@iconOnly}} @size='medium' />
      {{/if}}
  
      {{#if @iconRight}}
        <Rose::Icon @name={{@iconRight}} @size='medium' />
      {{/if}}
    </span>
  </button>
  */
  {
    "id": "eACRIyGG",
    "block": "[[[11,\"button\"],[17,1],[16,4,[52,[30,2],\"submit\",\"button\"]],[16,\"disabled\",[52,[30,3],\"disabled\"]],[16,0,[29,[[30,4],\"\\n    \",[52,[30,5],[28,[37,1],[\"rose-button-\",[30,5]],null]],\"\\n    \",[52,[30,6],\"has-icon-left\"],\"\\n    \",[52,[30,7],\"has-icon-right\"],\"\\n    \",[52,[30,8],\"has-icon-only\"]]]],[12],[1,\"\\n\\n  \"],[10,1],[14,0,\"rose-button-wrapper\"],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,6],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,1],[14,0,\"rose-button-body\"],[12],[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,8],[[[1,\"      \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,8],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,7],[[[1,\"      \"],[8,[39,2],null,[[\"@name\",\"@size\"],[[30,7],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@submit\",\"@disabled\",\"@classNames\",\"@style\",\"@iconLeft\",\"@iconRight\",\"@iconOnly\",\"&default\"],false,[\"if\",\"concat\",\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/button/index.hbs",
    "isStrictMode": false
  });

  class RoseButtonComponent extends _component2.default {// =attributes
  }

  _exports.default = RoseButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseButtonComponent);
});