define("core/decorators/confirm", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirm = confirm;

  /**
   * Decorates a method whereby calls to the method will first request
   * confirmation via the confirmation service.  If the user accepts, the
   * original method is executed.  If the user denies, it is not.
   */
  function confirm(confirmationTextKey, options) {
    return function (_target, _propertyKey, desc) {
      const method = desc.value;

      desc.value = function () {
        const owner = (0, _application.getOwner)(this);
        const confirmService = owner.lookup('service:confirm');
        const intl = owner.lookup('service:intl');
        const text = intl.t(confirmationTextKey);
        return confirmService.confirm(text, options) // If user confirmed, execute the decorated method
        .then(() => method.apply(this, arguments)) // If the user denied, gracefully handle promise rejection with no-op
        .catch(e => e);
      };
    };
  }
});