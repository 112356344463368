define("rose/components/rose/toolbar/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class='rose-toolbar'>
    {{yield
      (hash
        primary=(component
          'rose/anonymous' tagName='span' class='rose-toolbar-primary'
        )
        secondary=(component
          'rose/anonymous' tagName='span' class='rose-toolbar-secondary'
        )
      )
    }}
  </div>
  */
  {
    "id": "kKM3BRg3",
    "block": "[[[11,0],[17,1],[24,0,\"rose-toolbar\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"primary\",\"secondary\"],[[50,\"rose/anonymous\",0,null,[[\"tagName\",\"class\"],[\"span\",\"rose-toolbar-primary\"]]],[50,\"rose/anonymous\",0,null,[[\"tagName\",\"class\"],[\"span\",\"rose-toolbar-secondary\"]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/toolbar/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});