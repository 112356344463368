define("core/helpers/doc-url", ["exports", "@ember/component/helper", "@ember/application", "@ember/debug"], function (_exports, _helper, _application, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _helper.default {
    compute(params) {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const baseURL = config.documentation.baseURL;
      const docKey = params[0] || '';
      const configuredPath = config.documentation.topics[docKey];

      if (docKey) {
        (false && !(configuredPath) && (0, _debug.assert)(`
        Documentation for "${docKey}" could not be found. Please ensure that
        this key exists under "documentation" in your app config.
      `, configuredPath));
      }

      const path = configuredPath || '';
      return `${baseURL}${path}`;
    }

  }

  _exports.default = _default;
});