define("rose/components/rose/footer/nav/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class='rose-footer-nav' aria-label='footer-nav'>
    {{yield (hash link=(component 'rose/footer/nav/link'))}}
  </nav>
  */
  {
    "id": "5sE9FJwn",
    "block": "[[[10,\"nav\"],[14,0,\"rose-footer-nav\"],[14,\"aria-label\",\"footer-nav\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"link\"],[[50,\"rose/footer/nav/link\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/footer/nav/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});