define("api/generated/models/host-catalog", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  /**
   * HostCatalog manages Hosts and HostSets
   */
  let GeneratedHostCatalogModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec4 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('boolean', {
    description: 'Whether the catalog is disabled'
  }), _dec7 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec8 = (0, _model.attr)({
    description: 'Plugin information for this resource.',
    readOnly: true
  }), _dec9 = (0, _model.attr)('boolean', {
    isNestedAttribute: true,
    description: ''
  }), _dec10 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: ''
  }), _dec11 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: ''
  }), _dec12 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: ''
  }), _dec13 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: ''
  }), _dec14 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: ''
  }), _dec15 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: ''
  }), _dec16 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: ''
  }), _dec17 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: ''
  }), (_class = class GeneratedHostCatalogModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "plugin", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "disable_credential_rotation", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "region", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "access_key_id", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "secret_access_key", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "tenant_id", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "client_id", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "subscription_id", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "secret_id", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "secret_value", _descriptor17, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disabled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "plugin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disable_credential_rotation", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "region", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access_key_id", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "secret_access_key", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tenant_id", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_id", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "subscription_id", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "secret_id", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "secret_value", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedHostCatalogModel;
});