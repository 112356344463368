define("rose/components/rose/form/label/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class='rose-form-label{{if @error ' error'}}' for={{@for}} ...attributes>
    {{yield}}
  </label>
  */
  {
    "id": "tW5duuY0",
    "block": "[[[11,\"label\"],[16,0,[29,[\"rose-form-label\",[52,[30,1],\" error\"]]]],[16,\"for\",[30,2]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@error\",\"@for\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/label/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});