define("ember-composable-helpers/helpers/compute", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compute = compute;
  _exports.default = void 0;

  function compute(_ref) {
    let [action, ...params] = _ref;
    return action(...params);
  }

  var _default = (0, _helper.helper)(compute);

  _exports.default = _default;
});