define("api/models/group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/group", "@ember-data/model", "@ember/object", "@ember/array"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _group, _model, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let GroupModel = (_dec = (0, _model.attr)({
    readOnly: true,
    defaultValue: () => (0, _array.A)(),
    emptyArrayIfMissing: true
  }), _dec2 = (0, _object.computed)('member_ids.[]', 'store'), (_class = class GroupModel extends _group.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "member_ids", _descriptor, this);
    }

    /**
     * An array of resolved user instances.
     * Unresolvable instances are excluded from the array.
     * @type {[UserModel]}
     */
    // TODO: Use fragments array to read cross scope members.
    get members() {
      return this.member_ids.map(id => this.store.peekRecord('user', id)).filter(Boolean);
    } // =methods

    /**
     * Adds members via the `add-members` method.
     * See serializer and adapter for more information.
     * @param {[string]} memberIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addMembers(memberIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-members',
        memberIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete members via the `remove-members` method.
     * See serializer and adapter for more information.
     * @param {[string]} memberIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeMembers(memberIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-members',
        memberIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single member via the `remove-members` method.
     * @param {number} memberID
     * @param {object} options
     * @return {Promise}
     */


    removeMember(memberID, options) {
      return this.removeMembers([memberID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "member_ids", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "members", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "members"), _class.prototype)), _class));
  _exports.default = GroupModel;
});