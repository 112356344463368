define("api/abilities/account", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for accounts.
   */
  class AccountAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canSetPassword() {
      return this.hasAuthorizedAction('set-password');
    }

  }

  _exports.default = AccountAbility;
});