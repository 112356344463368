define("api/models/credential-library", ["exports", "api/generated/models/credential-library"], function (_exports, _credentialLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.options = void 0;

  /**
   * Enum options for credential library.
   */
  const options = {
    http_method: ['GET', 'POST']
  };
  _exports.options = options;

  class CredentialLibraryModel extends _credentialLibrary.default {}

  _exports.default = CredentialLibraryModel;
});