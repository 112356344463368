define("api/serializers/credential-store", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CredentialStoreSerializer extends _application.default {
    /**
     * @override
     * @method serialize
     * @param {Snapshot} snapshot
     */
    serialize() {
      const serialized = super.serialize(...arguments);

      if (serialized.attributes) {
        // Token cannot be unset.  If it's falsy, it must be removed.
        if (!serialized.attributes?.token) delete serialized.attributes.token; // Client certificate key cannot be unset when certificate is set.
        // If it's falsy, it must be removed.

        if (!serialized.attributes?.client_certificate_key && serialized.attributes?.client_certificate) delete serialized.attributes.client_certificate_key;
      }

      return serialized;
    }

  }

  _exports.default = CredentialStoreSerializer;
});