define("@glimmer/component/-private/base-component-manager", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component/-private/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = BaseComponentManager;

  /**
   * This factory function returns a component manager class with common behavior
   * that can be extend to add Glimmer.js- or Ember.js-specific functionality. As
   * these environments converge, the need for two component manager
   * implementations (and thus this factory) should go away.
   */
  function BaseComponentManager(setOwner, getOwner, capabilities) {
    return class {
      static create(attrs) {
        let owner = getOwner(attrs);
        return new this(owner);
      }

      constructor(owner) {
        (0, _defineProperty2.default)(this, "capabilities", capabilities);
        setOwner(this, owner);
      }

      createComponent(ComponentClass, args) {
        if (false
        /* DEBUG */
        ) {
          _component.ARGS_SET.set(args.named, true);
        }

        return new ComponentClass(getOwner(this), args.named);
      }

      getContext(component) {
        return component;
      }

    };
  }
});