define("@babel/runtime/helpers/esm/AwaitValue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _AwaitValue;

  function _AwaitValue(value) {
    this.wrapped = value;
  }
});