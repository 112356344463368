define("core/components/form/authenticate/oidc/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Rose::Form
    class="full-width"
    @onSubmit={{@submit}}
    @cancel={{false}}
    @disabled={{@disabled}}
    as |form|
  >
  
    <form.actions
      @submitText={{t "actions.authenticate"}}
      @showCancel={{false}} />
  
  </Rose::Form>
  
  */
  {
    "id": "KD9H3P06",
    "block": "[[[8,[39,0],[[24,0,\"full-width\"]],[[\"@onSubmit\",\"@cancel\",\"@disabled\"],[[30,1],false,[30,2]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,3,[\"actions\"]],null,[[\"@submitText\",\"@showCancel\"],[[28,[37,1],[\"actions.authenticate\"],null],false]],null],[1,\"\\n\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@submit\",\"@disabled\",\"form\"],false,[\"rose/form\",\"t\"]]",
    "moduleName": "core/components/form/authenticate/oidc/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});