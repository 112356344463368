define("@babel/runtime/helpers/esm/temporalRef", ["exports", "@babel/runtime/helpers/esm/temporalUndefined", "@babel/runtime/helpers/esm/tdz"], function (_exports, _temporalUndefined, _tdz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _temporalRef;

  function _temporalRef(val, name) {
    return val === _temporalUndefined.default ? (0, _tdz.default)(name) : val;
  }
});