define("api/serializers/fragment-string", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FragmentStringSerializer extends _json.default {
    // =methods

    /**
     * Serializes a to string.
     * @param {Snapshot} snapshot
     * @return {string}
     */
    serialize(snapshot) {
      return snapshot.attr('value');
    }
    /**
     * Normalizes from strings.
     * @param {Model} typeClass
     * @param {string} value
     * @return {object}
     */


    normalize(typeClass, value) {
      return super.normalize(typeClass, {
        value
      });
    }

  }

  _exports.default = FragmentStringSerializer;
});