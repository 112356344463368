define("rose/components/rose/dropdown/key-value/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <dl class='rose-dropdown-key-value rose-dropdown-item'>
    <dt class='rose-dropdown-key'>
      {{yield to='key'}}
    </dt>
  
    <dd class='rose-dropdown-value'>
      {{yield to='value'}}
    </dd>
  </dl>
  */
  {
    "id": "AvYtLjvR",
    "block": "[[[10,\"dl\"],[14,0,\"rose-dropdown-key-value rose-dropdown-item\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"rose-dropdown-key\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"dd\"],[14,0,\"rose-dropdown-value\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&key\",\"&value\"],false,[\"yield\"]]",
    "moduleName": "rose/components/rose/dropdown/key-value/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});