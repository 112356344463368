define("core/helpers/company-name", ["exports", "@ember/component/helper", "@ember/application"], function (_exports, _helper, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _helper.default {
    compute() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.companyName;
    }

  }

  _exports.default = _default;
});