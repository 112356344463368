define("api/generated/models/host", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  /**
   * Host contains all fields related to a Host resource
   */
  let GeneratedHostModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The owning host catalog ID.'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec4 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec8 = (0, _model.attr)({
    description: 'Plugin information for this resource.',
    readOnly: true
  }), (_class = class GeneratedHostModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "host_catalog_id", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "plugin", _descriptor8, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_catalog_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "plugin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedHostModel;
});