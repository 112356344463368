define("api/abilities/target", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for targets.
   */
  class TargetAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canConnect() {
      return this.hasAuthorizedAction('authorize-session');
    }
    /**
     * @type {boolean}
     */


    get canAddHostSources() {
      return this.hasAuthorizedAction('add-host-sources');
    }
    /**
     * @type {boolean}
     */


    get canRemoveHostSources() {
      return this.hasAuthorizedAction('remove-host-sources');
    }
    /**
     * @type {boolean}
     */


    get canAddCredentialSources() {
      return this.hasAuthorizedAction('add-credential-sources');
    }
    /**
     * @type {boolean}
     */


    get canRemoveCredentialSources() {
      return this.hasAuthorizedAction('remove-credential-sources');
    }

  }

  _exports.default = TargetAbility;
});