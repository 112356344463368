define("ember-simple-auth/initializers/setup-session", ["exports", "ember", "ember-simple-auth/internal-session", "ember-simple-auth/session-stores/ephemeral"], function (_exports, _ember, _internalSession, _ephemeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupSession;

  function setupSession(registry) {
    registry.register('session:main', _internalSession.default);

    if (_ember.default.testing) {
      registry.register('session-store:test', _ephemeral.default);
    }
  }
});