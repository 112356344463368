define("emberx-select/templates/components/x-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ihBEtGKm",
    "block": "[[[18,1,[[28,[37,1],null,[[\"option\"],[[50,\"x-option\",0,null,[[\"select\",\"register\",\"unregister\"],[[30,0],[28,[37,3],[[30,0],\"registerOption\"],null],[28,[37,3],[[30,0],\"unregisterOption\"],null]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "emberx-select/templates/components/x-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});