define("core/helpers/resource-filter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component/helper", "@ember/service", "@ember/application", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _helper, _service, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  /**
   * Use this helper to fetch the current value of a resource filter param,
   * as well as its allowedValues.
   *
   * @see resourceFilter (decorator)
   *
   * @example
   *
   *   {{#with resource-filter 'status' as |param|}}
   *     {{param.name}} / {{param.value}} / {{param.selectedValue}}
   *   {{/with}}
   */
  let ResourceFilterHelper = (_class = class ResourceFilterHelper extends _helper.default {
    // =services
    // =methods
    // =lifecycle management methods

    /**
     * Recompute this helper when the route changes.
     */
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      this.router.on('routeDidChange', this.routeDidChange);
    }
    /**
     * Stop listening to route events when this helper is destroyed.
     */


    willDestroy() {
      this.router.off('routeDidChange', this.routeDidChange);
      super.willDestroy();
    } // =compute method

    /**
     * Returns an object containing `name`, `value`, and `selectedValue`.
     * `name` - The name of the resource filter param.
     * `value` - The value of the resource filter param
     * @return {object}
     */


    compute(_ref) {
      let [routeName, name] = _ref;
      const owner = (0, _application.getOwner)(this);
      const containerKey = `resource-filter:${name}@${routeName}`;
      const resourceFilter = owner.lookup(containerKey);
      const {
        allowedValues,
        value: selectedValue
      } = resourceFilter;
      return {
        name,
        allowedValues,
        selectedValue
      };
    } // =actions

    /**
     * Triggers a recompute (see above) when the route changes, especially when
     * route query params change.
     */


    routeDidChange() {
      this.recompute();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeDidChange"), _class.prototype)), _class);
  _exports.default = ResourceFilterHelper;
});