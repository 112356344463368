define("api/serializers/session", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SessionSerializer extends _application.default {
    // =methods

    /**
     * If `adapterOptions.method` is `cancel`, the serialization should
     * include the version field only.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      let serialized = super.serialize(...arguments);

      if (snapshot?.adapterOptions?.method === 'cancel') {
        serialized = this.serializeForCancel(snapshot);
      }

      return serialized;
    }
    /**
     * Returns a payload containing only version
     * @param {Snapshot} snapshot
     * @return {object}
     */


    serializeForCancel(snapshot) {
      return {
        version: snapshot.attr('version')
      };
    }

  }

  _exports.default = SessionSerializer;
});