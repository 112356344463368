define("auth/session-stores/local-storage", ["exports", "ember-simple-auth/session-stores/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * This session store is intended for use with Ember Simple Auth when requesting
   * that the server send a full token (e.g. cookies are not requested,
   * see authenticators).
   *
   * NOTE:  when using a local storage scheme, authorization is not provided
   * automatically.  In order to achieve authorization... TODO
   *
   * @example
   *
   *   import LocalStorageSessionStore from 'auth/session-stores/local-storage';
   *   export default class ApplicationSessionStore extends LocalStorageSessionStore {
   *
   *   }
   *
   */
  class AuthLocalStorageSessionStore extends _localStorage.default {}

  _exports.default = AuthLocalStorageSessionStore;
});