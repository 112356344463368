define("ember-intl/-private/utils/empty-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  const proto = Object.create(null, {
    constructor: {
      value: undefined,
      enumerable: false,
      writable: true
    }
  }); // eslint-disable-next-line @typescript-eslint/no-empty-function

  function EmptyObject() {}

  EmptyObject.prototype = proto;
  /**
   * @private
   * @hide
   */

  var _default = EmptyObject;
  _exports.default = _default;
});