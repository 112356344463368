define("rose/components/rose/dialog/body/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='rose-dialog-body' tabindex='0'>
    {{yield}}
  </div>
  */
  {
    "id": "Tr2DbPQy",
    "block": "[[[10,0],[14,0,\"rose-dialog-body\"],[14,\"tabindex\",\"0\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "rose/components/rose/dialog/body/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});