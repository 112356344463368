define("ember-radio-button/components/radio-button-input", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/runloop", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _component2, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input ...attributes type="radio" aria-checked={{this.checkedStr}} value={{@value}} {{on "change" this.change}} />
  
  */
  {
    "id": "ltaWDD7V",
    "block": "[[[11,\"input\"],[17,1],[24,4,\"radio\"],[16,\"aria-checked\",[30,0,[\"checkedStr\"]]],[16,2,[30,2]],[4,[38,0],[\"change\",[30,0,[\"change\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@value\"],false,[\"on\"]]",
    "moduleName": "ember-radio-button/components/radio-button-input.hbs",
    "isStrictMode": false
  });

  let RadioButtonInputComponent = (_class = class RadioButtonInputComponent extends _component2.default {
    get checkedStr() {
      const checked = this.args.checked;

      if (typeof checked === 'boolean') {
        return checked.toString();
      }

      return null;
    }

    change() {
      if (this.args.groupValue !== this.args.value) {
        // this.set('groupValue', value);
        (0, _runloop.once)(this.args, 'changed', this.args.value);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  _exports.default = RadioButtonInputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioButtonInputComponent);
});