define("ember-composable-helpers/helpers/optional", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.optional = optional;
  _exports.default = void 0;

  function optional(_ref) {
    let [action] = _ref;

    if (typeof action === 'function') {
      return action;
    }

    return i => i;
  }

  var _default = (0, _helper.helper)(optional);

  _exports.default = _default;
});