define("ember-intl/-private/store/translation", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-intl/-private/utils/flatten", "ember-intl/-private/utils/parse"], function (_exports, _defineProperty2, _flatten, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  class Translation {
    get localeName() {
      return this._localeName;
    }

    constructor(localeName) {
      (0, _defineProperty2.default)(this, "translations", new Map());
      (0, _defineProperty2.default)(this, "asts", new Map());
      (0, _defineProperty2.default)(this, "_localeName", void 0);
      this._localeName = localeName;
    }

    addTranslations(translations) {
      const flatTranslations = (0, _flatten.default)(translations);

      for (const key in flatTranslations) {
        let translation = flatTranslations[key]; // If it's not a string, coerce it to one.

        if (typeof translation !== 'string') {
          translation = `${translation}`;
        }

        this.translations.set(key, translation);
        this.asts.set(key, (0, _parse.default)(translation));
      }
    }

    find(key) {
      if (this.has(key)) {
        return {
          ast: this.asts.get(key),
          original: this.translations.get(key)
        };
      }
    }

    has(key) {
      return this.translations.has(key);
    }

  }

  var _default = Translation;
  _exports.default = _default;
});