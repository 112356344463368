define("ember-browser-services/utils/proxy-service", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/service"], function (_exports, _defineProperty2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.proxyService = proxyService;

  /**
   * Allows Services to behave as Proxy objects for real objects, such as
   * window, document, navigator, Worker, etc.
   *
   * useful for consistently accessing unmockable objects and then replacing them
   * with fakes in testing.
   *
   * would it be worth recursively wrapping in a proxy for any reason?
   *
   * NOTE: This only works for one layer deep of properties
   *
   * @param {Object | Class} browserObject - the api to wrap a service around.
   */
  function proxyService(ObjectToProxy) {
    // extending the types for the static method create is too hard / impossible
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let UnTypedService = _service.default;

    function instanceHandlerFor(browserObject) {
      return {
        get(targetInstance, prop, receiver) {
          if (prop in targetInstance) {
            return Reflect.get(targetInstance, prop, receiver);
          }

          let value = browserObject[prop];

          if (typeof value === 'function') {
            // prevents the error "Illegal Invocation"
            // which can sometimes happen due to losing the "this" depending on
            // the invocation context at the call site
            return value.bind(browserObject);
          }

          return value;
        },

        set(targetInstance, prop, value, receiver) {
          if (prop in targetInstance) {
            Reflect.set(targetInstance, prop, value, receiver);
          }

          browserObject[prop] = value;
          return true;
        }

      };
    }

    function isConstructable(proxyTo) {
      return typeof proxyTo === 'function';
    } // We have to untype the Service, because...
    // this is nuts:
    // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/da0e5b5/types/ember__object/core.d.ts#L82-L94
    //
    // it's also all private, and the ember-TS team apparently didn't think people would want to do this :D


    class ProxyCreator extends UnTypedService {
      // https://github.com/emberjs/ember.js/blob/master/packages/%40ember/service/index.js#L66-L74
      // https://github.com/emberjs/ember.js/blob/f85cefe9855b2521b02800d4bb2b68da7db2a214/packages/%40ember/service/index.js#L68-L72
      static create(injections) {
        let serviceInstance = class ProxiedService extends _service.default {}.create(injections);
        let browserObject = isConstructable(ObjectToProxy) ? new ObjectToProxy() : ObjectToProxy;
        serviceInstance.__browser_object__ = browserObject;
        return new Proxy(serviceInstance, instanceHandlerFor(browserObject));
      }

      constructor() {
        super(...arguments);
        throw new Error('ProxyCreator is not new-able');
      }

    }

    (0, _defineProperty2.default)(ProxyCreator, "isServiceFactory", true);
    return ProxyCreator;
  }
});