define("api/models/managed-group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/managed-group", "@ember-data/model", "@ember/array"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _managedGroup, _model, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let ManagedGroupModel = (_dec = (0, _model.attr)({
    readOnly: true,
    defaultValue: () => (0, _array.A)(),
    emptyArrayIfMissing: true
  }), (_class = class ManagedGroupModel extends _managedGroup.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "member_ids", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "member_ids", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ManagedGroupModel;
});