define("api/generated/models/group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  /**
   * Group contains all fields related to a Group resource
   */
  let GeneratedGroupModel = (_dec = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec3 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec4 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec5 = (0, _model.attr)('boolean', {
    description: 'Whether the resource is disabled'
  }), _dec6 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), (_class = class GeneratedGroupModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor6, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedGroupModel;
});