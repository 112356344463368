define("rose/components/rose/dropdown/section/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id", "@ember/object"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section
    class='rose-dropdown-section'
    aria-labelledby={{concat 'dropdown-section-title-' this.id}}
  >
    {{#if @title}}
      <h2
        id={{concat 'dropdown-section-title-' this.id}}
        class='rose-dropdown-section-title'
      >
        {{@title}}
      </h2>
    {{/if}}
  
    {{yield}}
  </section>
  */
  {
    "id": "pM7bBh/h",
    "block": "[[[10,\"section\"],[14,0,\"rose-dropdown-section\"],[15,\"aria-labelledby\",[28,[37,0],[\"dropdown-section-title-\",[30,0,[\"id\"]]],null]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"h2\"],[15,1,[28,[37,0],[\"dropdown-section-title-\",[30,0,[\"id\"]]],null]],[14,0,\"rose-dropdown-section-title\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@title\",\"&default\"],false,[\"concat\",\"if\",\"yield\"]]",
    "moduleName": "rose/components/rose/dropdown/section/index.hbs",
    "isStrictMode": false
  });

  class RoseDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseDialogComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseDialogComponent);
});