define("ember-window-mock/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setCurrentHandler = _exports.default = void 0;
  let exportedWindow;

  let _setCurrentHandler;

  _exports._setCurrentHandler = _setCurrentHandler;

  if (false
  /* DEBUG */
  ) {
    // this Proxy handler will be used to preserve the unaltered behavior of the window global by default
    const doNothingHandler = {
      get(target, prop) {
        const value = Reflect.get(target, prop); // make sure the function receives the original window as the this context! (e.g. alert will throw an invalid invocation error)

        if (typeof value === 'function') {
          return new Proxy(value, {
            apply(t, _thisArg, argumentsList) {
              return Reflect.apply(value, target, argumentsList);
            }

          });
        }

        return value;
      },

      set: Reflect.set,
      has: Reflect.has,
      deleteProperty: Reflect.deleteProperty
    };
    let currentHandler = doNothingHandler; // private function to replace the default handler in tests

    _exports._setCurrentHandler = _setCurrentHandler = function () {
      let handler = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : doNothingHandler;
      return currentHandler = handler;
    };

    const proxyHandler = {
      get() {
        return currentHandler.get(...arguments);
      },

      set() {
        return currentHandler.set(...arguments);
      },

      has() {
        return currentHandler.has(...arguments);
      },

      deleteProperty() {
        return currentHandler.deleteProperty(...arguments);
      }

    };
    exportedWindow = new Proxy(window, proxyHandler);
  } else {
    exportedWindow = window;
  }

  var _default = exportedWindow;
  _exports.default = _default;
});