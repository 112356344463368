define("api/models/fragment-principal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let FragmentPrincipal = (_dec = (0, _model.attr)('string', {
    description: 'ID of the related scope'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'ID of the related principal (user or group)'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Type of the related principal (user or group)'
  }), (_class = class FragmentPrincipal extends _fragment.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "scope_id", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "principal_id", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor3, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scope_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "principal_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FragmentPrincipal;
});