define("ember-concurrency/-private/external/task-instance/cancelation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.didCancel = didCancel;
  _exports.CancelRequest = _exports.CANCEL_KIND_PARENT_CANCEL = _exports.CANCEL_KIND_LIFESPAN_END = _exports.CANCEL_KIND_YIELDABLE_CANCEL = _exports.CANCEL_KIND_EXPLICIT = _exports.TASK_CANCELATION_NAME = void 0;
  const TASK_CANCELATION_NAME = 'TaskCancelation';
  /**
   * Returns true if the object passed to it is a TaskCancelation error.
   * If you call `someTask.perform().catch(...)` or otherwise treat
   * a {@linkcode TaskInstance} like a promise, you may need to
   * handle the cancelation of a TaskInstance differently from
   * other kinds of errors it might throw, and you can use this
   * convenience function to distinguish cancelation from errors.
   *
   * ```js
   * click() {
   *   this.myTask.perform().catch(e => {
   *     if (!didCancel(e)) { throw e; }
   *   });
   * }
   * ```
   *
   * @param {object} error the caught error, which might be a TaskCancelation
   * @returns {boolean}
   */

  _exports.TASK_CANCELATION_NAME = TASK_CANCELATION_NAME;

  function didCancel(e) {
    return e && e.name === TASK_CANCELATION_NAME;
  }

  const CANCEL_KIND_EXPLICIT = 'explicit';
  _exports.CANCEL_KIND_EXPLICIT = CANCEL_KIND_EXPLICIT;
  const CANCEL_KIND_YIELDABLE_CANCEL = 'yielded';
  _exports.CANCEL_KIND_YIELDABLE_CANCEL = CANCEL_KIND_YIELDABLE_CANCEL;
  const CANCEL_KIND_LIFESPAN_END = 'lifespan_end';
  _exports.CANCEL_KIND_LIFESPAN_END = CANCEL_KIND_LIFESPAN_END;
  const CANCEL_KIND_PARENT_CANCEL = 'parent_cancel';
  _exports.CANCEL_KIND_PARENT_CANCEL = CANCEL_KIND_PARENT_CANCEL;

  class CancelRequest {
    constructor(kind, reason) {
      this.kind = kind;
      this.reason = reason;
      this.promise = new Promise(resolve => {
        this.finalize = resolve;
      });
    }

  }

  _exports.CancelRequest = CancelRequest;
});