define("rose/components/rose/table/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table
    ...attributes
    class='rose-table {{if @style (concat 'rose-table-' @style)}}'
  >
    {{#if @caption}}
      <caption>{{@caption}}</caption>
    {{/if}}
  
    {{yield
      (hash
        header=(component
          'rose/table/section' tagName='thead' classNames='rose-table-header'
        )
        body=(component
          'rose/table/section' tagName='tbody' classNames='rose-table-body'
        )
        footer=(component
          'rose/table/section' tagName='tfoot' classNames='rose-table-footer'
        )
      )
    }}
  </table>
  */
  {
    "id": "QS4re+zU",
    "block": "[[[11,\"table\"],[17,1],[16,0,[29,[\"rose-table \",[52,[30,2],[28,[37,1],[\"rose-table-\",[30,2]],null]]]]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"caption\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,4,[[28,[37,3],null,[[\"header\",\"body\",\"footer\"],[[50,\"rose/table/section\",0,null,[[\"tagName\",\"classNames\"],[\"thead\",\"rose-table-header\"]]],[50,\"rose/table/section\",0,null,[[\"tagName\",\"classNames\"],[\"tbody\",\"rose-table-body\"]]],[50,\"rose/table/section\",0,null,[[\"tagName\",\"classNames\"],[\"tfoot\",\"rose-table-footer\"]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@style\",\"@caption\",\"&default\"],false,[\"if\",\"concat\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/table/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});