define("@babel/runtime/helpers/esm/arrayWithHoles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _arrayWithHoles;

  function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }
});