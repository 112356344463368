define("core/helpers/format-date-iso", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Takes a `Date` instance and returns a string formatted in ISO,
   * e.g. "2020-01-01T00:00:00.999Z".
   */
  var _default = (0, _helper.helper)(function formatDateIso(params
  /*, hash*/
  ) {
    return params[0].toISOString();
  });

  _exports.default = _default;
});