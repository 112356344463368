define("core/components/image/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span ...attributes class='image-container'>
    {{inline-svg @name}}
  </span>
  */
  {
    "id": "bQZ3Mkg5",
    "block": "[[[11,1],[17,1],[24,0,\"image-container\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,2]],null]],[1,\"\\n\"],[13]],[\"&attrs\",\"@name\"],false,[\"inline-svg\"]]",
    "moduleName": "core/components/image/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});