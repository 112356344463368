define("rose/components/rose/message/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <article ...attributes class='rose-message'>
  
    {{#if @title}}
      <header class='rose-message-header'>
        {{#if @icon}}
          <Rose::Icon @name={{@icon}} />
        {{/if}}
  
        <h2 class='rose-message-title'>{{@title}}</h2>
  
        {{#if @subtitle}}
          <h3 class='rose-message-subtitle'>{{@subtitle}}</h3>
        {{/if}}
      </header>
    {{/if}}
  
    <section class='rose-message-body'>
      {{yield
        (hash
          description=(component
            'rose/anonymous' tagName='p' class='rose-message-description'
          )
          link=(component 'link-to' class='rose-message-link')
        )
      }}
    </section>
  
  </article>
  */
  {
    "id": "vEjXMHZz",
    "block": "[[[11,\"article\"],[17,1],[24,0,\"rose-message\"],[12],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"header\"],[14,0,\"rose-message-header\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[8,[39,1],null,[[\"@name\"],[[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"rose-message-title\"],[12],[1,[30,2]],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"        \"],[10,\"h3\"],[14,0,\"rose-message-subtitle\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"section\"],[14,0,\"rose-message-body\"],[12],[1,\"\\n    \"],[18,5,[[28,[37,3],null,[[\"description\",\"link\"],[[50,\"rose/anonymous\",0,null,[[\"tagName\",\"class\"],[\"p\",\"rose-message-description\"]]],[50,\"link-to\",0,null,[[\"class\"],[\"rose-message-link\"]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@title\",\"@icon\",\"@subtitle\",\"&default\"],false,[\"if\",\"rose/icon\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/message/index.hbs",
    "isStrictMode": false
  });

  class RoseMessageComponent extends _component2.default {}

  _exports.default = RoseMessageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseMessageComponent);
});