define("ember-composable-helpers/helpers/pipe-action", ["exports", "@ember/component/helper", "ember-composable-helpers/helpers/pipe", "ember-composable-helpers/-private/closure-action"], function (_exports, _helper, _pipe, _closureAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const closurePipe = _pipe.pipe;

  if (_closureAction.default) {
    closurePipe[_closureAction.default] = true;
  }

  var _default = (0, _helper.helper)(closurePipe);

  _exports.default = _default;
});