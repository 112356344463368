define("ember-intl/-private/formatters/format-time", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-intl/-private/formatters/-format-datetime"], function (_exports, _defineProperty2, _formatDatetime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  /**
   * @private
   * @hide
   */
  class FormatTime extends _formatDatetime.default {}

  _exports.default = FormatTime;
  (0, _defineProperty2.default)(FormatTime, "type", 'time');
});