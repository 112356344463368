define("ember-concurrency/-private/external/scheduler/policies/drop-policy", ["exports", "ember-concurrency/-private/external/scheduler/policies/bounded-policy", "ember-concurrency/-private/external/scheduler/policies/execution-states"], function (_exports, _boundedPolicy, _executionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CANCELLED = (0, _executionStates.makeCancelState)(`it belongs to a 'drop' Task that was already running`);

  class DropReducer {
    constructor(remainingSlots) {
      this.remainingSlots = remainingSlots;
    }

    step() {
      if (this.remainingSlots > 0) {
        this.remainingSlots--;
        return _executionStates.STARTED;
      }

      return CANCELLED;
    }

  }

  class DropPolicy extends _boundedPolicy.default {
    makeReducer() {
      return new DropReducer(this.maxConcurrency);
    }

  }

  var _default = DropPolicy;
  _exports.default = _default;
});