define("rose/components/rose/form/fieldset/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <fieldset
    ...attributes
    class='rose-form-fieldset'
    aria-describedby={{if
      (has-block 'description')
      (concat 'description-' this.id)
    }}
  >
    <legend id={{concat 'title-' this.id}} class='rose-form-fieldset-title'>
      {{yield to='title'}}
    </legend>
  
    {{#if (has-block 'description')}}
      <div
        id={{concat 'description-' this.id}}
        class='rose-form-fieldset-description'
      >
        {{yield to='description'}}
      </div>
    {{/if}}
  
    <div class='rose-form-fieldset-body'>
      {{yield to='body'}}
    </div>
  </fieldset>
  */
  {
    "id": "R7d7Ukco",
    "block": "[[[11,\"fieldset\"],[17,1],[24,0,\"rose-form-fieldset\"],[16,\"aria-describedby\",[52,[48,[30,2]],[28,[37,2],[\"description-\",[30,0,[\"id\"]]],null]]],[12],[1,\"\\n  \"],[10,\"legend\"],[15,1,[28,[37,2],[\"title-\",[30,0,[\"id\"]]],null]],[14,0,\"rose-form-fieldset-title\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[10,0],[15,1,[28,[37,2],[\"description-\",[30,0,[\"id\"]]],null]],[14,0,\"rose-form-fieldset-description\"],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"rose-form-fieldset-body\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&description\",\"&title\",\"&body\"],false,[\"if\",\"has-block\",\"concat\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/fieldset/index.hbs",
    "isStrictMode": false
  });

  class RoseFormFieldsetComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseFormFieldsetComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormFieldsetComponent);
});