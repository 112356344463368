define("rose/components/rose/dialog/cover/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='rose-dialog-cover-modal-overlay'></div>
  
  <section
    ...attributes
    role='dialog'
    class='rose-dialog-cover'
    id={{this.id}}
    aria-describedby={{concat 'dialog-cover-header-' this.id}}
    tabindex='0'
    {{focus-trap}}
  >
    <header
      class='rose-dialog-cover-header'
      id={{concat 'dialog-cover-header-' this.id}}
    >
      {{yield to='header'}}
    </header>
    <div class='rose-dialog-cover-body'>
      {{yield to='body'}}
    </div>
  </section>
  */
  {
    "id": "G/VjcePr",
    "block": "[[[10,0],[14,0,\"rose-dialog-cover-modal-overlay\"],[12],[13],[1,\"\\n\\n\"],[11,\"section\"],[17,1],[24,\"role\",\"dialog\"],[24,0,\"rose-dialog-cover\"],[16,1,[30,0,[\"id\"]]],[16,\"aria-describedby\",[28,[37,0],[\"dialog-cover-header-\",[30,0,[\"id\"]]],null]],[24,\"tabindex\",\"0\"],[4,[38,1],null,null],[12],[1,\"\\n  \"],[10,\"header\"],[14,0,\"rose-dialog-cover-header\"],[15,1,[28,[37,0],[\"dialog-cover-header-\",[30,0,[\"id\"]]],null]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"rose-dialog-cover-body\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&header\",\"&body\"],false,[\"concat\",\"focus-trap\",\"yield\"]]",
    "moduleName": "rose/components/rose/dialog/cover/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});