define("api/initializers/fragment-string", ["exports", "api/serializers/fragment-string"], function (_exports, _fragmentString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // app/initializers/fragment-serializer.js
  function initialize(application) {
    application.register('serializer:fragment-string', _fragmentString.default);
  } // Fragment serializers must be manually registered


  var _default = {
    name: 'fragment-string',
    initialize: initialize
  };
  _exports.default = _default;
});