define("api/initializers/fragment-auth-method-attributes-account-claim-map-serializer", ["exports", "api/serializers/fragment-auth-method-attributes-account-claim-map"], function (_exports, _fragmentAuthMethodAttributesAccountClaimMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // app/initializers/fragment-serializer.js
  function initialize(application) {
    application.register('serializer:fragment-auth-method-attributes-account-claim-map', _fragmentAuthMethodAttributesAccountClaimMap.default);
  } // Fragment serializers must be manually registered


  var _default = {
    name: 'fragment-auth-method-attributes-account-claim-map',
    initialize: initialize
  };
  _exports.default = _default;
});