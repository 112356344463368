define("rose/utilities/component-auto-id", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateComponentID = generateComponentID;

  /**
   * A function that generates a component ID, based on UUID.
   * @return {string}
   */
  function generateComponentID() {
    return `component-${(0, _emberUuid.v1)()}`;
  }
});