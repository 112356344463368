define("rose/modifiers/on-click-inside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Used to detect clicks occuring _inside_ the modified element.
   * This is useful especially for dropdowns to toggle content visibility
   * when the user clicks an item.
   */
  var _default = (0, _emberModifier.modifier)((element, _ref) => {
    let [callback] = _ref;

    function handleClick(event) {
      if (element.contains(event.target)) {
        callback(element, event);
      }
    }

    element.addEventListener('click', handleClick, false);
    return () => {
      element.removeEventListener('click', handleClick, false);
    };
  });

  _exports.default = _default;
});