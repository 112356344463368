define("rose/components/rose/dropdown/button/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    ...attributes
    type={{if @submit 'submit' 'button'}}
    disabled={{if @disabled 'disabled'}}
    class='rose-dropdown-button
      {{if @style (concat 'rose-dropdown-button-' @style)}}
      {{if @iconOnly 'has-icon-only'}}'
  >
    <span class='rose-dropdown-button-wrapper'>
      <span class='rose-dropdown-button-body'>
        {{yield}}
      </span>
  
      {{#if @iconOnly}}
        <Rose::Icon @name={{@iconOnly}} @size='medium' />
      {{/if}}
    </span>
  </button>
  */
  {
    "id": "oNcG5oOw",
    "block": "[[[11,\"button\"],[17,1],[16,4,[52,[30,2],\"submit\",\"button\"]],[16,\"disabled\",[52,[30,3],\"disabled\"]],[16,0,[29,[\"rose-dropdown-button\\n    \",[52,[30,4],[28,[37,1],[\"rose-dropdown-button-\",[30,4]],null]],\"\\n    \",[52,[30,5],\"has-icon-only\"]]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"rose-dropdown-button-wrapper\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"rose-dropdown-button-body\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"      \"],[8,[39,3],null,[[\"@name\",\"@size\"],[[30,5],\"medium\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@submit\",\"@disabled\",\"@style\",\"@iconOnly\",\"&default\"],false,[\"if\",\"concat\",\"yield\",\"rose/icon\"]]",
    "moduleName": "rose/components/rose/dropdown/button/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});