define("rose/components/rose/frame/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section ...attributes class='rose-frame'>
  
    <header class='rose-frame-header'>
      {{yield to='header'}}
    </header>
  
    <div class='rose-frame-body'>
      {{yield to='body'}}
    </div>
  
  </section>
  */
  {
    "id": "rKM7fpxq",
    "block": "[[[11,\"section\"],[17,1],[24,0,\"rose-frame\"],[12],[1,\"\\n\\n  \"],[10,\"header\"],[14,0,\"rose-frame-header\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"rose-frame-body\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"&header\",\"&body\"],false,[\"yield\"]]",
    "moduleName": "rose/components/rose/frame/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});