define("ember-concurrency/-private/external/task/task-group", ["exports", "ember-concurrency/-private/external/task/taskable"], function (_exports, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskGroup = void 0;

  class TaskGroup extends _taskable.Taskable {}

  _exports.TaskGroup = TaskGroup;
});