define("ember-can/computed", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ability = ability;

  function ability(abilityName, resourceName) {
    resourceName = resourceName || abilityName;
    return (0, _object.computed)(resourceName, function () {
      let canService = (0, _application.getOwner)(this).lookup('service:can');
      return canService.abilityFor(abilityName, (0, _object.get)(this, resourceName));
    }).readOnly();
  }
});