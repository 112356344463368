define("rose/components/rose/table/row/cell/index", ["exports", "@ember/component", "@ember/template-factory", "rose/components/rose/table/row/cell/index", "@ember/object"], function (_exports, _component, _templateFactory, _index, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "AyEcrFf9",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "rose/components/rose/table/row/cell/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    layout: _index.default,
    tagName: 'td',
    classNameBindings: ['cellTypeStyle', 'cellShrink', 'cellAlignmentStyle'],
    cellTypeStyle: (0, _object.computed)(function () {
      return this.tagName.match('th') ? `rose-table-header-cell` : 'rose-table-cell';
    }),
    cellShrink: (0, _object.computed)(function () {
      if (this.shrink) {
        return 'rose-table-cell-shrink';
      }
    }),
    cellAlignmentStyle: (0, _object.computed)(function () {
      if (this.alignRight) {
        return 'align-right';
      }

      if (this.alignCenter) {
        return 'align-center';
      }
    })
  }));

  _exports.default = _default;
});