define("ember-browser-services/services/browser/window", ["exports", "@ember/service", "ember-browser-services/utils/proxy-service"], function (_exports, _service, _proxyService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * In order to have thorough testing, we should only interact with the window
   * (and other browser APIs) via a service.
   *
   * We can control, mock, and override the services, but we can't do so with
   * the browser APIs.
   *
   */
  const WindowProxyService = (0, _proxyService.proxyService)(window);
  var _default = WindowProxyService;
  _exports.default = _default;
});