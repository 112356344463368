define("core/helpers/format-date-iso-human", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Takes a `Date` instance and returns a string formatted in human-friendly
   * ISO-ish, e.g. "2020-01-01 00:00:00".
   */
  var _default = (0, _helper.helper)(function formatDateIsoHuman(params
  /*, hash*/
  ) {
    return params[0].toISOString().replace('T', ' ').replace(/\.\d*Z/, '');
  });

  _exports.default = _default;
});