define("rose/components/rose/form/radio/card/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "rose/components/rose/form/radio/radio"], function (_exports, _defineProperty2, _radio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RoseFormRadioCardComponent extends _radio.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", this.args.layout);
      (0, _defineProperty2.default)(this, "className", `rose-form-radio-card ${this.layout}`);
    }

  }

  _exports.default = RoseFormRadioCardComponent;
});