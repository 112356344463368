define("rose/components/rose/form/errors/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='rose-form-errors' id={{@id}} ...attributes>
    {{yield (hash message=(component 'rose/form/errors/message'))}}
  </div>
  */
  {
    "id": "Tmmodi5b",
    "block": "[[[11,0],[24,0,\"rose-form-errors\"],[16,1,[30,1]],[17,2],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"message\"],[[50,\"rose/form/errors/message\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/form/errors/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});