define("ember-concurrency/-private/external/scheduler/policies/unbounded-policy", ["exports", "ember-concurrency/-private/external/scheduler/policies/execution-states"], function (_exports, _executionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UnboundedReducer {
    step() {
      return _executionStates.STARTED;
    }

  }

  const SINGLETON_REDUCER = new UnboundedReducer();

  class UnboundedPolicy {
    makeReducer() {
      return SINGLETON_REDUCER;
    }

  }

  var _default = UnboundedPolicy;
  _exports.default = _default;
});