define("core/components/toolbar-refresher/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.lastRefreshed}}
    <Rose::Toolbar::Info>
      <RelativeDatetimeLive @date={{this.lastRefreshed}} />
    </Rose::Toolbar::Info>
  {{/if}}
  
  <LoadingButton @onClick={{this.onClick}}>
    {{t 'actions.refresh'}}
  </LoadingButton>
  */
  {
    "id": "DY4mmhR3",
    "block": "[[[41,[30,0,[\"lastRefreshed\"]],[[[1,\"  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@date\"],[[30,0,[\"lastRefreshed\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@onClick\"],[[30,0,[\"onClick\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,4],[\"actions.refresh\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"if\",\"rose/toolbar/info\",\"relative-datetime-live\",\"loading-button\",\"t\"]]",
    "moduleName": "core/components/toolbar-refresher/index.hbs",
    "isStrictMode": false
  });

  let ToolbarRefresherComponent = (_class = class ToolbarRefresherComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "lastRefreshed", _descriptor, this);
    }

    // =methods

    /**
     * Executes the click handler argument and trackes the time it completed.
     */
    async onClick() {
      await this.args.onClick();
      this.lastRefreshed = new Date();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "lastRefreshed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = ToolbarRefresherComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ToolbarRefresherComponent);
});