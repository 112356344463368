define("core/components/relative-datetime-live/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.formatted}}
  
  */
  {
    "id": "moHVKAQB",
    "block": "[[[1,[30,0,[\"formatted\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "core/components/relative-datetime-live/index.hbs",
    "isStrictMode": false
  });

  const MILLISECOND = 1;
  const SECOND = MILLISECOND * 1000;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const MONTH = DAY * 31;
  const YEAR = MONTH * 12; // common time intervals in milliseconds

  const unitMappings = [{
    unit: 'second',
    value: SECOND
  }, {
    unit: 'minute',
    value: MINUTE
  }, {
    unit: 'hour',
    value: HOUR
  }, {
    unit: 'day',
    value: DAY
  }, {
    unit: 'month',
    value: MONTH
  }, {
    unit: 'year',
    value: YEAR
  }];
  let RelativeDatetimeLiveComponent = (_class = class RelativeDatetimeLiveComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "clockTick", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
    }

    // =properties

    /**
     * The difference in milliseconds between the date argument and the
     * current time.
     * @type {number}
     */
    get delta() {
      return this.args.date.valueOf() - this.clockTick.now;
    }
    /**
     * The greatest meaningful unit is the unit which delta is equal to or
     * greater than.  For example, the unit of a delta equivalent of 59 seconds is
     * 'second' while 60 seconds is 'minute', 36 hours is 'day', etc.
     * @type {object}
     */


    get greatestMeaningfulUnit() {
      return unitMappings.reduce((currentValue, previousValue) => {
        return Math.abs(this.delta) >= previousValue.value ? previousValue : currentValue;
      }, unitMappings[0]);
    }
    /**
     * The delta value scaled to the greatest meaningful unit.  For example, if
     * the delta is 1000 ms, the scaled value is 1, which corresponds to
     * a GMU of 'second'.  A delta of 60000 ms is also 1, corresponding to a GMU
     * of 'minute'.
     * @type {number}
     */


    get scaledDelta() {
      if (this.delta < 0) {
        return Math.ceil(this.delta / this.greatestMeaningfulUnit.value);
      }

      return Math.floor(this.delta / this.greatestMeaningfulUnit.value);
    }
    /**
     * A string representation of the greatest meaningful unit.
     * @type {string}
     */


    get unit() {
      return this.greatestMeaningfulUnit.unit;
    }
    /**
     * A localizd relative datetime.  E.g. "30 seconds ago".
     * @type {string}
     */


    get formatted() {
      return this.intl.formatRelative(this.scaledDelta, {
        unit: this.unit,
        numeric: 'auto'
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clockTick", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RelativeDatetimeLiveComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelativeDatetimeLiveComponent);
});