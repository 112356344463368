define("rose/components/rose/layout/page/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class='rose-layout-page'>
    {{yield
      (hash
        breadcrumbs=(component
          'rose/anonymous' class='rose-layout-page-breadcrumbs'
        )
        header=(component 'rose/anonymous' class='rose-layout-page-header')
        actions=(component 'rose/anonymous' class='rose-layout-page-actions')
        navigation=(component
          'rose/anonymous' class='rose-layout-page-navigation'
        )
        body=(component 'rose/anonymous' class='rose-layout-page-body')
      )
    }}
  </div>
  */
  {
    "id": "c6FZQUOM",
    "block": "[[[11,0],[17,1],[24,0,\"rose-layout-page\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"breadcrumbs\",\"header\",\"actions\",\"navigation\",\"body\"],[[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-page-breadcrumbs\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-page-header\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-page-actions\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-page-navigation\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-page-body\"]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/layout/page/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});