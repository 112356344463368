define("rose/components/rose/form/helper-text/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class='rose-form-helper-text{{if @error ' error'}}'
    id={{@id}}
    ...attributes
  >
    {{yield}}
    {{#if @link}}
      {{#if @linkText}}
        <a
          href={{@link}}
          target='_blank'
          rel='noreferrer noopener'
        >{{@linkText}}</a>
      {{/if}}
    {{/if}}
  </span>
  */
  {
    "id": "/uo9GMbK",
    "block": "[[[11,1],[16,0,[29,[\"rose-form-helper-text\",[52,[30,1],\" error\"]]]],[16,1,[30,2]],[17,3],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[41,[30,4],[[[41,[30,5],[[[1,\"      \"],[10,3],[15,6,[30,4]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer noopener\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null]],[]],null],[13]],[\"@error\",\"@id\",\"&attrs\",\"@link\",\"@linkText\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/helper-text/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});