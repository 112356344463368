define("ember-loading/decorator", ["exports", "@ember/debug", "@ember/application"], function (_exports, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loading;

  function loading(_target, _propertyKey, desc) {
    let orig = desc.value;
    (false && !(typeof orig === 'function') && (0, _debug.assert)('The @loading decorator must be applied to methods', typeof orig === 'function'));

    desc.value = function () {
      let owner = (0, _application.getOwner)(this);
      (false && !(!!owner) && (0, _debug.assert)('The target of the @loading decorator must have an owner.', !!owner));
      let service = owner.lookup('service:loading');
      return service.run(this, orig, ...arguments);
    };
  }

  ;
});