define("ember-browser-services/services/browser/document", ["exports", "@ember/service", "ember-browser-services/utils/proxy-service"], function (_exports, _service, _proxyService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DocumentProxyService = (0, _proxyService.proxyService)(document);
  /**
   * In order to have thorough testing, we should only interact with the document
   * (and other browser APIs) via a service.
   *
   * We can control, mock, and override the services, but we can't do so with
   * the browser APIs.
   *
   */

  var _default = DocumentProxyService;
  _exports.default = _default;
});