define("ember-intl/-private/utils/is-array-equal", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @private
   * @hide
   */
  function _default(a, b) {
    if (!(0, _array.isArray)(a) || !(0, _array.isArray)(b)) {
      return false;
    }

    if (a === b) {
      return true;
    }

    return a.toString() === b.toString();
  }
});