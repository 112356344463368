define("api/models/target", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/target", "@ember-data/model", "ember-data-model-fragments/attributes", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _target, _model, _attributes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let TargetModel = (_dec = (0, _model.attr)('number', {
    isNestedAttribute: true,
    description: 'The  default port a target should use if present.'
  }), _dec2 = (0, _attributes.fragmentArray)('fragment-host-source', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec3 = (0, _attributes.fragmentArray)('fragment-string', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec4 = (0, _object.computed)('host_sources.[]', 'store'), _dec5 = (0, _object.computed)('application_credential_source_ids.[]', 'store'), (_class = class TargetModel extends _target.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "default_port", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "host_sources", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "application_credential_source_ids", _descriptor3, this);
    }

    /**
     * An array of resolved host set and host catalog instances.  Model instances
     * must already be loaded into the store (this method will not load unloaded
     * instances).  Unresolvable instances are excluded from the array.
     * @type {[{model: HostSetModel, hostCatalog: HostCatalogModel}]}
     */
    get hostSets() {
      return this.host_sources.map(_ref => {
        let {
          host_source_id,
          host_catalog_id
        } = _ref;
        return {
          model: this.store.peekRecord('host-set', host_source_id),
          hostCatalog: this.store.peekRecord('host-catalog', host_catalog_id)
        };
      }).filter(hostSetRef => hostSetRef.model !== null);
    }
    /**
     * An array of resolved credential library instances.  Model instances
     * must already be loaded into the store (this method will not load unloaded
     * instances).  Unresolvable instances are excluded from the array.
     * @type {[CredentialLibraryModel]}
     */


    get credentialLibraries() {
      return this.application_credential_source_ids.map(source => this.store.peekRecord('credential-library', source.value)).filter(Boolean);
    }
    /**
     * Sessions associated with this target (but only already loaded sessions).
     * @type {SessionModel[]}
     */


    get sessions() {
      return this.store.peekAll('session').filter(s => s && s.target_id === this.id);
    }
    /**
     * The project associated with this target (if already loaded).
     * @type {ScopeModel}
     */


    get project() {
      return this.store.peekRecord('scope', this.scopeID);
    }
    /**
     * True if any sessions associated with this target are active or pending.
     * @type {boolean}
     */


    get isActive() {
      const pendingOrActiveSessions = this.sessions.filter(s => s.isActive || s.isPending);
      return Boolean(pendingOrActiveSessions.length);
    } // =methods

    /**
     * Adds host sets via the `add-host-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostSetIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addHostSources(hostSetIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-host-sources',
        hostSetIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete host sets via the `remove-host-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostSetIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeHostSources(hostSetIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-host-sources',
        hostSetIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single host set via the `remove-host-sources` method.
     * @param {number} hostSetID
     * @param {object} options
     * @return {Promise}
     */


    removeHostSource(hostSetID, options) {
      return this.removeHostSources([hostSetID], options);
    }
    /**
     * Adds credential libraries via the `add-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} credentialLibraryIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addCredentialSources(credentialLibraryIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-credential-sources',
        credentialLibraryIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete credential libraries via the `remove-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} credentialLibraryIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeCredentialSources(credentialLibraryIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-credential-sources',
        credentialLibraryIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single credential library set via the `remove-credential-sources` method.
     * @param {number} credentialLibraryID
     * @param {object} options
     * @return {Promise}
     */


    removeCredentialSource(credentialLibraryID, options) {
      return this.removeCredentialSources([credentialLibraryID], options);
    }
    /**
     * True if the target type is tcp.
     * @type {boolean}
     */


    get isTCP() {
      return this.type === 'tcp';
    }
    /**
     * True if the target type is ssh.
     * @type {boolean}
     */


    get isSSH() {
      return this.type === 'ssh';
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "default_port", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_sources", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "application_credential_source_ids", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hostSets", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "hostSets"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "credentialLibraries", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "credentialLibraries"), _class.prototype)), _class));
  _exports.default = TargetModel;
});