define("core/helpers/is-loading", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component/helper", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor;

  /**
   * This helper returns true or false based on the state of the ember-loading
   * service's `isLoading` attribute.
   */
  let _class = (_class2 = class _class2 extends _helper.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "loading", _descriptor, this);
    }

    // =methods

    /**
     * Returns true if "something" is loading according to ember-loading.
     * @return {boolean}
     */
    compute() {
      return this.loading.isLoading;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loading", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});