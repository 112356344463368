define("api/models/auth-token", ["exports", "api/generated/models/auth-token"], function (_exports, _authToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthTokenModel extends _authToken.default {}

  _exports.default = AuthTokenModel;
});