define("api/abilities/credential-store", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for credential store.
   */
  class CredentialStoreAbility extends _model.default {
    // =permissions

    /**
     * Only "known" credential store types may be read.
     * @type {boolean}
     */
    get canRead() {
      return !this.model.isStatic && this.hasAuthorizedAction('read');
    }

  }

  _exports.default = CredentialStoreAbility;
});