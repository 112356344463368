define("ember-concurrency/-private/external/scheduler/state-tracker/null-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NullState {
    onCompletion() {}

    onPerformed() {}

    onStart() {}

    onRunning() {}

    onQueued() {}

  }

  var _default = NullState;
  _exports.default = _default;
});