define("core/components/error/message/index", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "rose/components/rose/message", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _message, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Rose::Message
    @title={{t (concat "errors." this.messageCode ".title")}}
    @subtitle={{concat (t "errors.generic.title") " " @status}}
    @icon={{this.icon}}
  as |message|>
    <message.description>{{t (concat "errors." this.messageCode ".description")}}</message.description>
    {{#if this.helpRoute}}
      <message.link @route={{this.helpRoute}}>{{t "actions.help"}}</message.link>
    {{/if}}
  </Rose::Message>
  
  */
  {
    "id": "Uay5PPnH",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@subtitle\",\"@icon\"],[[28,[37,1],[[28,[37,2],[\"errors.\",[30,0,[\"messageCode\"]],\".title\"],null]],null],[28,[37,2],[[28,[37,1],[\"errors.generic.title\"],null],\" \",[30,1]],null],[30,0,[\"icon\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"description\"]],null,null,[[\"default\"],[[[[1,[28,[35,1],[[28,[37,2],[\"errors.\",[30,0,[\"messageCode\"]],\".description\"],null]],null]]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"helpRoute\"]],[[[1,\"    \"],[8,[30,2,[\"link\"]],null,[[\"@route\"],[[30,0,[\"helpRoute\"]]]],[[\"default\"],[[[[1,[28,[35,1],[\"actions.help\"],null]]],[]]]]],[1,\"\\n\"]],[]],null]],[2]]]]],[1,\"\\n\"]],[\"@status\",\"message\"],false,[\"rose/message\",\"t\",\"concat\",\"if\"]]",
    "moduleName": "core/components/error/message/index.hbs",
    "isStrictMode": false
  });

  /*
   * Helpful error booleans are attached based on the error status code:
   *
   *   - 401 - `error.isUnauthenticated`:  the session isn't authenticated
   *   - 403 - `error.isForbidden`:  the session is authenticated but does not have
   *            permission to perform the requested action
   *   - 404 - `error.isNotFound`:  the requested resource could not be found
   *   - 500 - `error.isServer`:  an internal server error occurred
   *
   * For an unknown error state, i.e error state not matching to the above defined list:
   *   - unknown - `error.isUnknown`:  an error occurred, but we don't know which or
   *            we don't distinguish it yet
   */
  const statuses = ['401', '403', '404', '500'];
  let ErrorMessageComponent = (_dec = (0, _object.computed)('args.status'), _dec2 = (0, _object.computed)('args.status'), (_class = class ErrorMessageComponent extends _message.default {
    // =methods

    /**
     * Returns an icon for error status.
     * @return {string}
     */
    get icon() {
      switch (this.args.status) {
        case '404':
          return 'flight-icons/svg/help-16';

        case '401':
        case '403':
        default:
          return 'flight-icons/svg/alert-circle-16';
      }
    } // TODO: Enable when help documentation can be linked in.

    /**
     * Returns an route string for help text in message.
     * @return {string}
     */
    // get helpRoute() {
    //   return 'index'
    // }

    /**
     * Returns 'unknown' status code when provided error status
     * isn't part of predefined statuses.
     * @return {string}
     */


    get messageCode() {
      let messageCode = this.args.status;
      if (!statuses.includes(messageCode)) messageCode = 'unknown';
      return messageCode;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "icon", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "messageCode", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "messageCode"), _class.prototype)), _class));
  _exports.default = ErrorMessageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ErrorMessageComponent);
});