define("ember-simple-auth/utils/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = () => window.location;

  _exports.default = _default;
});