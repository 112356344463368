define("rose/components/rose/footer/copyright/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _defineProperty2, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class='rose-footer-copyright'>
    <span class='rose-footer-copyright-symbol'>
      &copy;
    </span>
    <span class='rose-footer-copyright-year'>
      {{this.currentYear}}
    </span>
    <span class='rose-footer-copyright-text'>
      {{@text}}
    </span>
  </span>
  */
  {
    "id": "qA65SmZV",
    "block": "[[[10,1],[14,0,\"rose-footer-copyright\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"rose-footer-copyright-symbol\"],[12],[1,\"\\n    ©\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"rose-footer-copyright-year\"],[12],[1,\"\\n    \"],[1,[30,0,[\"currentYear\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"rose-footer-copyright-text\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@text\"],false,[]]",
    "moduleName": "rose/components/rose/footer/copyright/index.hbs",
    "isStrictMode": false
  });

  class RoseFooterCopyrightComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "currentYear", new Date().getFullYear());
    }

  }

  _exports.default = RoseFooterCopyrightComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFooterCopyrightComponent);
});