define("core/initializers/deprecations", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const config = application.resolveRegistration('config:environment');
    const isTesting = config.environment === 'test';

    if (isTesting) {
      // Disable all deprecations for now in tests.
      (0, _debug.registerDeprecationHandler)(() => {
        return;
      });
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});