define("api/serializers/host-set", ["exports", "@babel/runtime/helpers/esm/defineProperty", "api/serializers/application", "ember-copy"], function (_exports, _defineProperty2, _application, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fieldByType = {
    aws: ['preferred_endpoints', 'filters', 'sync_interval_seconds'],
    azure: ['preferred_endpoints', 'filter', 'sync_interval_seconds']
  };

  class HostSetSerializer extends _application.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "serializeScopeID", false);
    }

    // =methods

    /**
     * If `adapterOptions.serializeHostIDs` is true, the serialization should
     * include **only host_ids** and the version.  Normally, these are not
     * serialized.
     * @override
     * @param {Snapshot} snapshot
     * @return {object}
     */
    serialize(snapshot) {
      const hostIDs = snapshot?.adapterOptions?.hostIDs;

      if (hostIDs) {
        return this.serializeWithHostIDs(snapshot, hostIDs);
      } else {
        switch (snapshot.record.compositeType) {
          case 'static':
            return this.serializeStatic(...arguments);

          default:
            return super.serialize(...arguments);
        }
      }
    }
    /**
     * Returns a payload containing only the host_ids array.
     * @param {Snapshot} snapshot
     * @param {[string]} hostIDs
     * @return {object}
     */


    serializeWithHostIDs(snapshot, hostIDs) {
      return {
        version: snapshot.attr('version'),
        host_ids: hostIDs
      };
    }

    serializeStatic() {
      let serialized = super.serialize(...arguments); // Delete unnecessary fields for static type

      delete serialized.attributes;
      delete serialized.preferred_endpoints;
      delete serialized.sync_interval_seconds;
      return serialized;
    }

    serializeAttribute(snapshot, json, key, attribute) {
      const value = super.serializeAttribute(...arguments);
      const {
        isPlugin,
        compositeType
      } = snapshot.record;
      const {
        options
      } = attribute;

      if (isPlugin && options.isNestedAttribute) {
        if (!fieldByType[compositeType].includes(key)) {
          delete json.attributes[key];
        }
      }

      return value;
    }
    /**
     * Temporarily converts `filters` to an array if it is a string.  This is a
     * quirk of the API.
     *
     * TODO:  remove once API consistently returns arrays.
     */


    normalize(typeClass, hash) {
      const normalizedHash = (0, _emberCopy.copy)(hash, true);

      if (typeof normalizedHash?.filters === 'string') {
        normalizedHash.filters = [normalizedHash.filters];
      }

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      const normalized = super.normalize(typeClass, normalizedHash, ...rest);
      return normalized;
    }

  }

  _exports.default = HostSetSerializer;
});