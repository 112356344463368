define("api/models/scope", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "api/generated/models/scope", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _scope, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.scopeTypes = void 0;

  var _dec, _dec2, _dec3, _class;

  const scopeTypes = {
    global: 'global',
    org: 'org',
    project: 'project'
  };
  _exports.scopeTypes = scopeTypes;
  let ScopeModel = (_dec = (0, _object.computed)('type'), _dec2 = (0, _object.computed)('type'), _dec3 = (0, _object.computed)('type'), (_class = class ScopeModel extends _scope.default {
    // =attributes

    /**
     * @type {boolean}
     */
    get isGlobal() {
      return this.type === scopeTypes.global;
    } // There is only one global scope and it cannot be created by clients,
    // thus no set.

    /**
     * @type {boolean}
     */


    get isOrg() {
      return this.type === scopeTypes.org;
    }

    set isOrg(value) {
      if (value) this.type = scopeTypes.org;
    }
    /**
     * @type {boolean}
     */


    get isProject() {
      return this.type === scopeTypes.project;
    }

    set isProject(value) {
      if (value) this.type = scopeTypes.project;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "isGlobal", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isGlobal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isOrg", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isOrg"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProject", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isProject"), _class.prototype)), _class));
  _exports.default = ScopeModel;
});