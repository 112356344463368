define("core/session-stores/application", ["exports", "auth/session-stores/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSessionStore extends _localStorage.default {} // import CookieSessionStore from 'auth/session-stores/cookie';
  // import config from '../config/environment';
  //
  // export default class ApplicationSessionStore extends CookieSessionStore {
  //
  //   // =attributes
  //
  //   /**
  //    * @type {string}
  //    */
  //   cookiePath = config.rootURL;
  //
  //   /**
  //    * @type {string}
  //    */
  //   cookieName = config.auth.passwordCookieName;
  //
  // }


  _exports.default = ApplicationSessionStore;
});