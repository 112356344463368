define("ember-concurrency/-private/external/scheduler/state-tracker/null-state-tracker", ["exports", "ember-concurrency/-private/external/scheduler/state-tracker/null-state"], function (_exports, _nullState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NULL_STATE = new _nullState.default();

  class NullStateTracker {
    stateFor() {
      return NULL_STATE;
    }

    computeFinalStates() {}

  }

  var _default = NullStateTracker;
  _exports.default = _default;
});