define("api/abilities/role", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for roles.
   */
  class RoleAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canSetGrants() {
      return this.hasAuthorizedAction('set-grants');
    }
    /**
     * @type {boolean}
     */


    get canAddPrincipals() {
      return this.hasAuthorizedAction('add-principals');
    }
    /**
     * @type {boolean}
     */


    get canRemovePrincipals() {
      return this.hasAuthorizedAction('remove-principals');
    }

  }

  _exports.default = RoleAbility;
});