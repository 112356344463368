define("rose/components/rose/list/key-value/item/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li
    class='rose-list-key-value-item'
    aria-labelledby={{concat 'item-key-' this.id}}
  >
    {{yield
      (hash
        key=(component
          'rose/anonymous'
          id=(concat 'item-key-' this.id)
          tagName='div'
          classNames='rose-list-key-value-item-cell'
        )
        cell=(component
          'rose/anonymous'
          tagName='div'
          classNames='rose-list-key-value-item-cell'
        )
      )
    }}
  </li>
  */
  {
    "id": "DKF6Ws0M",
    "block": "[[[10,\"li\"],[14,0,\"rose-list-key-value-item\"],[15,\"aria-labelledby\",[28,[37,0],[\"item-key-\",[30,0,[\"id\"]]],null]],[12],[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"key\",\"cell\"],[[50,\"rose/anonymous\",0,null,[[\"id\",\"tagName\",\"classNames\"],[[28,[37,0],[\"item-key-\",[30,0,[\"id\"]]],null],\"div\",\"rose-list-key-value-item-cell\"]]],[50,\"rose/anonymous\",0,null,[[\"tagName\",\"classNames\"],[\"div\",\"rose-list-key-value-item-cell\"]]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"concat\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/list/key-value/item/index.hbs",
    "isStrictMode": false
  });

  class RoseListKeyValueItemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseListKeyValueItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseListKeyValueItemComponent);
});