define("rose/components/rose/form/select/select/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <XSelect
    ...attributes
    class='rose-form-select-field{{if @error ' error'}}'
    id={{@id}}
    name={{@name}}
    disabled={{@disabled}}
    @value={{@value}}
    @onChange={{@onChange}}
    aria-describedby={{@aria-describedby}}
    as |xs|
  >
    {{yield xs}}
  </XSelect>
  */
  {
    "id": "kUua2pJW",
    "block": "[[[8,[39,0],[[17,1],[16,0,[29,[\"rose-form-select-field\",[52,[30,2],\" error\"]]]],[16,1,[30,3]],[16,3,[30,4]],[16,\"disabled\",[30,5]],[16,\"aria-describedby\",[30,6]]],[[\"@value\",\"@onChange\"],[[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n  \"],[18,10,[[30,9]]],[1,\"\\n\"]],[9]]]]]],[\"&attrs\",\"@error\",\"@id\",\"@name\",\"@disabled\",\"@aria-describedby\",\"@value\",\"@onChange\",\"xs\",\"&default\"],false,[\"x-select\",\"if\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/select/select/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});