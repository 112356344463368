define("ember-concurrency/-private/external/environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Environment = void 0;

  class Environment {
    assert() {}

    async() {}

    reportUncaughtRejection() {}

    defer() {}

    globalDebuggingEnabled() {}

  }

  _exports.Environment = Environment;
});