define("api/generated/models/auth-token", ["exports", "api/models/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * NOTE:  we don't yet use this model for display in the UI and thus no
   * attributes are included at this time.  This model is used only for
   * validating that an auth token exists.
   */
  class GeneratedAuthTokenModel extends _base.default {}

  _exports.default = GeneratedAuthTokenModel;
});