define("@babel/runtime/helpers/esm/classStaticPrivateMethodSet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _classStaticPrivateMethodSet;

  function _classStaticPrivateMethodSet() {
    throw new TypeError("attempted to set read only static private field");
  }
});