define("ember-composable-helpers/helpers/from-entries", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromEntries = fromEntries;
  _exports.default = void 0;

  function fromEntries(_ref) {
    let [entries] = _ref;

    if (!entries) {
      return entries;
    }

    return Object.fromEntries(entries);
  }

  var _default = (0, _helper.helper)(fromEntries);

  _exports.default = _default;
});