define("api/adapters/host-catalog", ["exports", "api/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HostCatalogAdapter extends _application.default {
    /**
     * Overrides the default `buildURL` such that on _create_, URLs for records of
     * type `plugin` get an extra query parameter `?plugin_name`.  All other
     * request types behave as normal.
     *
     * @override
     * @param {string} modelName
     * @param {string} id
     * @return {string} url
     * @return {string} requestType
     */
    buildURL(modelName, id, snapshot, requestType) {
      let url = super.buildURL(...arguments);

      if (requestType === 'createRecord') {
        const pluginName = snapshot.attr('plugin')?.name;
        if (pluginName) url = `${url}?plugin_name=${pluginName}`;
      }

      return url;
    }

  }

  _exports.default = HostCatalogAdapter;
});