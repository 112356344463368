define("api/serializers/fragment-auth-method-attributes-account-claim-map", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FragmentHostSourceSerializer extends _json.default {
    // =methods

    /**
     * Serializes an account claim to string.
     * @param {Snapshot} snapshot
     * @return {string}
     */
    serialize(snapshot) {
      const from = snapshot.attr('from');
      const to = snapshot.attr('to');
      return `${from}=${to}`;
    }
    /**
     * Normalizes account claim strings into JSON API objects.
     * @param {Model} typeClass
     * @param {string} value
     * @return {object}
     */


    normalize(typeClass, value) {
      const normalizedHash = {
        from: value.split('=')[0],
        to: value.split('=')[1]
      };
      return super.normalize(typeClass, normalizedHash);
    }

  }

  _exports.default = FragmentHostSourceSerializer;
});