define("rose/modifiers/on-click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Used to detect clicks occuring _outside_ of the modified element.
   * This is useful especially for dropdowns to toggle content visibility
   * when the user clicks away.
   */
  var _default = (0, _emberModifier.modifier)((element, _ref) => {
    let [callback] = _ref;

    function handleClick(event) {
      if (!element.contains(event.target)) {
        callback(element, event);
      }
    }

    document.addEventListener('click', handleClick, false);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  _exports.default = _default;
});