define("api/models/host-set", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/host-set", "ember-data-model-fragments/attributes", "api/models/host-catalog"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _hostSet, _attributes, _hostCatalog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let HostSetModel = (_dec = (0, _attributes.fragmentArray)('fragment-string', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec2 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true
  }), _dec3 = (0, _attributes.fragmentArray)('fragment-string', {
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), (_class = class HostSetModel extends _hostSet.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "host_ids", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "preferred_endpoints", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor3, this);
    }

    // =attributes

    /**
     * Returns if the host-set is static or not.
     * @type {boolean}
     */
    get isStatic() {
      return this.type === 'static';
    }
    /**
     * Returns if the host-set is plugin or not.
     * @type {boolean}
     */


    get isPlugin() {
      return this.type === 'plugin';
    }
    /**
     * True if the host set is an unknown type.
     * @type {boolean}
     */


    get isUnknown() {
      return this.isPlugin && !_hostCatalog.types.includes(this.plugin?.name);
    }
    /**
     * Returns if a host-set plugin is AWS or not
     * @type {boolean}
     */


    get isAWS() {
      return this.compositeType === 'aws';
    }
    /**
     * Return if a host-set plugin is Azure or not.
     */


    get isAzure() {
      return this.compositeType === 'azure';
    }
    /**
     * If a host-set is a plugin return its name,
     * otherwise returns the host-set type
     * @type {string}
     */


    get compositeType() {
      if (this.isUnknown) return 'unknown';
      if (this.isPlugin) return this.plugin.name;
      return 'static';
    }
    /**
     * Sets type, if type is different than static, set plugin name to type
     */


    set compositeType(type) {
      if (type === 'static') {
        this.type = 'static';
      } else {
        this.type = 'plugin';
        this.plugin = {
          name: type
        };
      }
    }
    /**
     * Host IDs are read-only under normal circumstances.  But these can
     * be persisted via a dedicated call to `addHosts()`.
     */


    // =methods

    /**
     * Adds hosts via the `add-hosts` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */
    addHosts(hostIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-hosts',
        hostIDs
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Add a single host via the `add-hosts` method.
     * @param {string} hostID
     * @param {object} options
     * @return {Promise}
     */


    addHost(hostID, options) {
      return this.addHosts([hostID], options);
    }
    /**
     * Delete hosts via the `remove-hosts` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeHosts(hostIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-hosts',
        hostIDs
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single host via the `remove-hosts` method.
     * @param {string} hostID
     * @param {object} options
     * @return {Promise}
     */


    removeHost(hostID, options) {
      return this.removeHosts([hostID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_ids", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preferred_endpoints", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HostSetModel;
});