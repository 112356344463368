define("ember-composable-helpers/helpers/reject-by", ["exports", "@ember/component/helper", "@ember/array", "@ember/utils", "@ember/object", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _array, _utils, _object, _isEqual, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rejectBy = rejectBy;
  _exports.default = void 0;

  function rejectBy(_ref) {
    let [byPath, value, array] = _ref;

    if (!(0, _array.isArray)(array) && (0, _array.isArray)(value)) {
      array = value;
      value = undefined;
    }

    array = (0, _asArray.default)(array);
    let filterFn;

    if ((0, _utils.isPresent)(value)) {
      if (typeof value === 'function') {
        filterFn = item => !value((0, _object.get)(item, byPath));
      } else {
        filterFn = item => !(0, _isEqual.default)((0, _object.get)(item, byPath), value);
      }
    } else {
      filterFn = item => !(0, _object.get)(item, byPath);
    }

    return array.filter(filterFn);
  }

  var _default = (0, _helper.helper)(rejectBy);

  _exports.default = _default;
});