define("rose/components/rose/form/errors/message/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class='rose-form-error-message' ...attributes>
    <Rose::Icon @name='flight-icons/svg/x-square-16' @size='medium' />
    {{yield}}
  </p>
  */
  {
    "id": "wkij8kws",
    "block": "[[[11,2],[24,0,\"rose-form-error-message\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@size\"],[\"flight-icons/svg/x-square-16\",\"medium\"]],null],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"rose/icon\",\"yield\"]]",
    "moduleName": "rose/components/rose/form/errors/message/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});