define("api/abilities/user", ["exports", "api/abilities/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides abilities for users.
   */
  class UserAbility extends _model.default {
    // =permissions

    /**
     * @type {boolean}
     */
    get canAddAccounts() {
      return this.hasAuthorizedAction('add-accounts');
    }
    /**
     * @type {boolean}
     */


    get canRemoveAccounts() {
      return this.hasAuthorizedAction('remove-accounts');
    }

  }

  _exports.default = UserAbility;
});