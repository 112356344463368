define("ember-truth-helpers/helpers/gt", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.default = void 0;

  function gt(_ref, hash) {
    let [left, right] = _ref;

    if (hash.forceNumber) {
      if (typeof left !== 'number') {
        left = Number(left);
      }

      if (typeof right !== 'number') {
        right = Number(right);
      }
    }

    return left > right;
  }

  var _default = (0, _helper.helper)(gt);

  _exports.default = _default;
});