define("rose/components/rose/nav/link/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model}}
    <LinkTo @route={{@route}} @model={{@model}} class='rose-nav-link'>
      {{yield}}
    </LinkTo>
  {{else}}
    <LinkTo @route={{@route}} class='rose-nav-link'>
      {{yield}}
    </LinkTo>
  {{/if}}
  */
  {
    "id": "6ba2E8vO",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[24,0,\"rose-nav-link\"]],[[\"@route\",\"@model\"],[[30,2],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[24,0,\"rose-nav-link\"]],[[\"@route\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@model\",\"@route\",\"&default\"],false,[\"if\",\"link-to\",\"yield\"]]",
    "moduleName": "rose/components/rose/nav/link/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});