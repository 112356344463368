define("api/serializers/host-catalog", ["exports", "api/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fieldsByType = {
    aws: ['disable_credential_rotation', 'region', 'access_key_id', 'secret_access_key'],
    azure: ['disable_credential_rotation', 'tenant_id', 'client_id', 'subscription_id', 'secret_id', 'secret_value']
  };

  class HostCatalogSerializer extends _application.default {
    // =methods
    serialize(snapshot) {
      const serialized = super.serialize(...arguments);

      switch (snapshot.record.compositeType) {
        case 'static':
          return this.serializeStatic(...arguments);

        default:
          return serialized;
      }
    }

    serializeAttribute(snapshot, json, key, attribute) {
      const value = super.serializeAttribute(...arguments);
      const {
        isPlugin,
        compositeType
      } = snapshot.record;
      const {
        options
      } = attribute; // Nested conditionals for readability
      // This deletes any fields that don't belong to the record type

      if (isPlugin && options.isNestedAttribute && json.attributes) {
        // The key must be included in the fieldsByType list above
        if (!fieldsByType[compositeType].includes(key)) delete json.attributes[key];
      } // This deletes any fields that don't belong to the record type


      if (isPlugin && options.isNestedSecret && json.secrets) {
        if (!fieldsByType[compositeType].includes(key)) delete json.secrets[key];
      } // new one for isNestedSecret


      return value;
    }

    serializeStatic() {
      const serialized = super.serialize(...arguments); // Delete unnecessary fields for static host-catalog

      delete serialized.attributes;
      delete serialized.secrets;
      return serialized;
    }

  }

  _exports.default = HostCatalogSerializer;
});