define("rose/components/rose/header/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class='rose-header'>
    {{yield
      (hash
        brand=(component 'rose/header/brand')
        utilities=(component 'rose/header/utilities')
        nav=(component 'rose/header/nav')
      )
    }}
  </div>
  */
  {
    "id": "UtSGME82",
    "block": "[[[11,0],[17,1],[24,0,\"rose-header\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"brand\",\"utilities\",\"nav\"],[[50,\"rose/header/brand\",0,null,null],[50,\"rose/header/utilities\",0,null,null],[50,\"rose/header/nav\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/header/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});