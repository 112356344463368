define("ember-intl/-private/formatters/format-number", ["exports", "@babel/runtime/helpers/esm/defineProperty", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _defineProperty2, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  const NUMBER_OPTIONS = ['localeMatcher', 'style', 'currency', 'currencyDisplay', 'unit', 'unitDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits', // Unified NumberFormat (Stage 3 as of 10/22/19)
  'compactDisplay', 'currencyDisplay', 'currencySign', 'notation', 'signDisplay', 'unitDisplay', 'unit'];
  /**
   * @private
   * @hide
   */

  class FormatNumber extends _base.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        return new Intl.NumberFormat(locales, options);
      }));
    }

    get options() {
      return NUMBER_OPTIONS;
    }

    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(value);
    }

  }

  _exports.default = FormatNumber;
  (0, _defineProperty2.default)(FormatNumber, "type", 'number');
});