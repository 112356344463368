define("rose/components/rose/nav/sidebar/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/component", "@ember/template-factory", "@glimmer/component", "rose/utilities/component-auto-id"], function (_exports, _defineProperty2, _component, _templateFactory, _component2, _componentAutoId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav
    ...attributes
    class='rose-nav-sidebar'
    id={{this.id}}
    title={{@title}}
    aria-labelledby={{if @title (concat 'title-' this.id)}}
  >
  
    {{#if @title}}
      <span class='rose-nav-title' id='title-{{this.id}}'>
        {{@title}}
      </span>
    {{/if}}
  
    {{yield (hash link=(component 'rose/nav/link'))}}
  
  </nav>
  */
  {
    "id": "1byuVfRq",
    "block": "[[[11,\"nav\"],[17,1],[24,0,\"rose-nav-sidebar\"],[16,1,[30,0,[\"id\"]]],[16,\"title\",[30,2]],[16,\"aria-labelledby\",[52,[30,2],[28,[37,1],[\"title-\",[30,0,[\"id\"]]],null]]],[12],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"rose-nav-title\"],[15,1,[29,[\"title-\",[30,0,[\"id\"]]]]],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,3,[[28,[37,3],null,[[\"link\"],[[50,\"rose/nav/link\",0,null,null]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@title\",\"&default\"],false,[\"if\",\"concat\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/nav/sidebar/index.hbs",
    "isStrictMode": false
  });

  class RoseNavSidebarComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "id", (0, _componentAutoId.generateComponentID)());
    }

  }

  _exports.default = RoseNavSidebarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseNavSidebarComponent);
});