define("rose/components/rose/layout/global/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section ...attributes class='rose-layout-global'>
    {{yield
      (hash
        header=(component
          'rose/anonymous' tagName='header' class='rose-layout-global-header'
        )
        body=(component 'rose/anonymous' class='rose-layout-global-body')
        footer=(component 'rose/anonymous' class='rose-layout-global-footer')
      )
    }}
  </section>
  */
  {
    "id": "quN1tc1P",
    "block": "[[[11,\"section\"],[17,1],[24,0,\"rose-layout-global\"],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"header\",\"body\",\"footer\"],[[50,\"rose/anonymous\",0,null,[[\"tagName\",\"class\"],[\"header\",\"rose-layout-global-header\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-global-body\"]]],[50,\"rose/anonymous\",0,null,[[\"class\"],[\"rose-layout-global-footer\"]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "rose/components/rose/layout/global/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});