define("ember-cookies/utils/serialize-cookie", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeCookie = void 0;

  const serializeCookie = function (name, value) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let cookie = `${name}=${value}`;

    if (!(0, _utils.isEmpty)(options.domain)) {
      cookie = `${cookie}; domain=${options.domain}`;
    }

    if ((0, _utils.typeOf)(options.expires) === 'date') {
      cookie = `${cookie}; expires=${options.expires.toUTCString()}`;
    }

    if (!(0, _utils.isEmpty)(options.maxAge)) {
      cookie = `${cookie}; max-age=${options.maxAge}`;
    }

    if (options.secure) {
      cookie = `${cookie}; secure`;
    }

    if (options.httpOnly) {
      cookie = `${cookie}; httpOnly`;
    }

    if (!(0, _utils.isEmpty)(options.path)) {
      cookie = `${cookie}; path=${options.path}`;
    }

    if (!(0, _utils.isEmpty)(options.sameSite)) {
      cookie = `${cookie}; SameSite=${options.sameSite}`;
    }

    return cookie;
  };

  _exports.serializeCookie = serializeCookie;
});