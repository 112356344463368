define("ember-notify/components/ember-notify", ["exports", "@ember/array", "@ember/object", "@ember/service", "@ember/component", "ember-notify/templates/components/ember-notify", "ember-notify/message"], function (_exports, _array, _object, _service, _component, _emberNotify, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UIkitTheme = _exports.SemanticUiTheme = _exports.RefillsTheme = _exports.BootstrapTheme = _exports.Foundation5Theme = _exports.FoundationTheme = _exports.Theme = _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _emberNotify.default,
    notify: (0, _service.inject)(),
    classNames: ['ember-notify-cn'],
    classNameBindings: ['classPrefix'],
    messageStyle: 'foundation',
    closeAfter: 2500,
    messages: null,
    source: _object.computed.oneWay('notify'),
    classPrefix: (0, _object.computed)('defaultClass', function () {
      return this.defaultClass || 'ember-notify-default';
    }),

    init() {
      this._super(...arguments);

      this.set('messages', (0, _array.A)());
      this.source.setTarget(this);
      let theme;

      switch (this.messageStyle) {
        case 'foundation':
          theme = FoundationTheme;
          break;

        case 'uikit':
          theme = UIkitTheme;
          break;

        case 'foundation-5':
          theme = Foundation5Theme;
          break;

        case 'bootstrap':
          theme = BootstrapTheme;
          break;

        case 'refills':
          theme = RefillsTheme;
          break;

        case 'semantic-ui':
          theme = SemanticUiTheme;
          break;

        default:
          throw new Error(`Unknown messageStyle ${this.messageStyle}.
          Options are 'foundation', 'foundation-5', 'uikit', 'refills', 'bootstrap', and 'semantic-ui'.`);
      }

      this.set('theme', theme);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.source.setTarget(null);
    },

    show(message) {
      if (this.isDestroyed) {
        return;
      }

      let id = message.id;

      if (id && this.messages.find(x => x.id === id)) {
        return;
      }

      if (!(message instanceof _message.default)) {
        message = _message.default.create(message);
      }

      this.messages.pushObject(message);
      return message;
    }

  });

  _exports.default = _default;
  const Theme = {
    classNamesFor(message) {
      return message.type;
    }

  };
  _exports.Theme = Theme;
  const FoundationTheme = {
    classNamesFor(message) {
      let classNames = ['callout', message.type];

      if (message.type === 'error') {
        classNames.push('alert');
      }

      return classNames.join(' ');
    }

  };
  _exports.FoundationTheme = FoundationTheme;
  const Foundation5Theme = {
    classNamesFor(message) {
      let classNames = ['alert-box', message.type];

      if (message.type === 'error') {
        classNames.push('alert');
      }

      return classNames.join(' ');
    }

  };
  _exports.Foundation5Theme = Foundation5Theme;
  const BootstrapTheme = {
    classNamesFor(message) {
      let {
        type
      } = message;

      if (type === 'alert' || type === 'error') {
        type = 'danger';
      }

      let classNames = ['alert', `alert-${type}`];
      return classNames.join(' ');
    }

  };
  _exports.BootstrapTheme = BootstrapTheme;
  const RefillsTheme = {
    classNamesFor(message) {
      let typeMapping = {
        success: 'success',
        alert: 'error',
        error: 'error',
        info: 'notice',
        warning: 'alert'
      };
      return `flash-${typeMapping[message.type]}`;
    }

  };
  _exports.RefillsTheme = RefillsTheme;
  const SemanticUiTheme = {
    classNamesFor(message) {
      let typeMapping = {
        success: 'success',
        alert: 'error',
        error: 'error',
        info: 'info',
        warning: 'warning'
      };
      return `ui message ${typeMapping[message.type]}`;
    }

  };
  _exports.SemanticUiTheme = SemanticUiTheme;
  const UIkitTheme = {
    classNamesFor(message) {
      let typeMapping = {
        success: 'success',
        alert: 'warning',
        error: 'danger',
        info: 'info',
        warning: 'warning'
      };
      return `uk-notify-message uk-notify-message-${typeMapping[message.type]}`;
    }

  };
  _exports.UIkitTheme = UIkitTheme;
});