/* This file is generated by build/debug.js */
function isMachineOp(value) {
  return value >= 0 && value <= 15;
}
function isOp(value) {
  return value >= 16;
}

/**
 * Registers
 *
 * For the most part, these follows MIPS naming conventions, however the
 * register numbers are different.
 */
// $0 or $pc (program counter): pointer into `program` for the next insturction; -1 means exit
const $pc = 0; // $1 or $ra (return address): pointer into `program` for the return

const $ra = 1; // $2 or $fp (frame pointer): pointer into the `evalStack` for the base of the stack

const $fp = 2; // $3 or $sp (stack pointer): pointer into the `evalStack` for the top of the stack

const $sp = 3; // $4-$5 or $s0-$s1 (saved): callee saved general-purpose registers

const $s0 = 4;
const $s1 = 5; // $6-$7 or $t0-$t1 (temporaries): caller saved general-purpose registers

const $t0 = 6;
const $t1 = 7; // $8 or $v0 (return value)

const $v0 = 8;
function isLowLevelRegister(register) {
  return register <= $sp;
}
var SavedRegister;

(function (SavedRegister) {
  SavedRegister[SavedRegister["s0"] = 4] = "s0";
  SavedRegister[SavedRegister["s1"] = 5] = "s1";
})(SavedRegister || (SavedRegister = {}));

var TemporaryRegister;

(function (TemporaryRegister) {
  TemporaryRegister[TemporaryRegister["t0"] = 6] = "t0";
  TemporaryRegister[TemporaryRegister["t1"] = 7] = "t1";
})(TemporaryRegister || (TemporaryRegister = {}));

export { isMachineOp, isOp, SavedRegister, TemporaryRegister, isLowLevelRegister, $pc, $fp, $ra, $sp, $s0, $s1, $t0, $t1, $v0 };
