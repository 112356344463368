define("ember-concurrency/helpers/task", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function taskHelper(_ref) {
    let [task, ...args] = _ref;
    return task._curry(...args);
  }

  var _default = (0, _helper.helper)(taskHelper);

  _exports.default = _default;
});