define("rose/components/rose/form/actions/index", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _applyDecoratedDescriptor2, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='rose-form-actions' ...attributes>
    <Rose::Button @disabled={{@submitDisabled}} @submit={{true}} @style='primary'>
      {{@submitText}}
    </Rose::Button>
  
    {{#if this.showCancel}}
      <Rose::Button
        @disabled={{@cancelDisabled}}
        @style='secondary'
        {{on 'click' @cancel}}
      >
        {{@cancelText}}
      </Rose::Button>
    {{/if}}
  </div>
  */
  {
    "id": "ccLcGEfT",
    "block": "[[[11,0],[24,0,\"rose-form-actions\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@disabled\",\"@submit\",\"@style\"],[[30,2],true,\"primary\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showCancel\"]],[[[1,\"    \"],[8,[39,0],[[4,[38,2],[\"click\",[30,5]],null]],[[\"@disabled\",\"@style\"],[[30,4],\"secondary\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,6]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@submitDisabled\",\"@submitText\",\"@cancelDisabled\",\"@cancel\",\"@cancelText\"],false,[\"rose/button\",\"if\",\"on\"]]",
    "moduleName": "rose/components/rose/form/actions/index.hbs",
    "isStrictMode": false
  });

  /**
   * A set of two form action buttons for submit and cancel.
   * To handle submit, place a submit handler on the parent form element.
   * To handle cancel button click, pass in a function to `@cancel`.
   *
   * @example
   *  <Rose::Form::Actions
   *    @submitText="Save"
   *    @cancelText="Cancel"
   *    @showCancel={{true}}
   *    @cancel=(fn @cancel) />
   */
  let RoseFormActionsComponent = (_dec = (0, _object.computed)('args.showCancel'), (_class = class RoseFormActionsComponent extends _component2.default {
    /**
     * @type {boolean}
     */
    get showCancel() {
      return this.args.showCancel ?? true;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "showCancel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showCancel"), _class.prototype)), _class));
  _exports.default = RoseFormActionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RoseFormActionsComponent);
});